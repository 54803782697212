// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-about-mdx": () => import("./../../../content/about.mdx" /* webpackChunkName: "component---content-about-mdx" */),
  "component---content-components-action-bar-mdx": () => import("./../../../content/components/action-bar.mdx" /* webpackChunkName: "component---content-components-action-bar-mdx" */),
  "component---content-components-action-list-mdx": () => import("./../../../content/components/action-list.mdx" /* webpackChunkName: "component---content-components-action-list-mdx" */),
  "component---content-components-action-list-react-deprecated-mdx": () => import("./../../../content/components/action-list/react/deprecated.mdx" /* webpackChunkName: "component---content-components-action-list-react-deprecated-mdx" */),
  "component---content-components-action-menu-mdx": () => import("./../../../content/components/action-menu.mdx" /* webpackChunkName: "component---content-components-action-menu-mdx" */),
  "component---content-components-action-menu-react-deprecated-mdx": () => import("./../../../content/components/action-menu/react/deprecated.mdx" /* webpackChunkName: "component---content-components-action-menu-react-deprecated-mdx" */),
  "component---content-components-anchored-overlay-mdx": () => import("./../../../content/components/anchored-overlay.mdx" /* webpackChunkName: "component---content-components-anchored-overlay-mdx" */),
  "component---content-components-autocomplete-mdx": () => import("./../../../content/components/autocomplete.mdx" /* webpackChunkName: "component---content-components-autocomplete-mdx" */),
  "component---content-components-avatar-mdx": () => import("./../../../content/components/avatar.mdx" /* webpackChunkName: "component---content-components-avatar-mdx" */),
  "component---content-components-avatar-pair-mdx": () => import("./../../../content/components/avatar-pair.mdx" /* webpackChunkName: "component---content-components-avatar-pair-mdx" */),
  "component---content-components-avatar-stack-mdx": () => import("./../../../content/components/avatar-stack.mdx" /* webpackChunkName: "component---content-components-avatar-stack-mdx" */),
  "component---content-components-banner-mdx": () => import("./../../../content/components/banner.mdx" /* webpackChunkName: "component---content-components-banner-mdx" */),
  "component---content-components-blankslate-mdx": () => import("./../../../content/components/blankslate.mdx" /* webpackChunkName: "component---content-components-blankslate-mdx" */),
  "component---content-components-border-box-mdx": () => import("./../../../content/components/border-box.mdx" /* webpackChunkName: "component---content-components-border-box-mdx" */),
  "component---content-components-box-mdx": () => import("./../../../content/components/box.mdx" /* webpackChunkName: "component---content-components-box-mdx" */),
  "component---content-components-branch-name-mdx": () => import("./../../../content/components/branch-name.mdx" /* webpackChunkName: "component---content-components-branch-name-mdx" */),
  "component---content-components-breadcrumbs-mdx": () => import("./../../../content/components/breadcrumbs.mdx" /* webpackChunkName: "component---content-components-breadcrumbs-mdx" */),
  "component---content-components-button-group-mdx": () => import("./../../../content/components/button-group.mdx" /* webpackChunkName: "component---content-components-button-group-mdx" */),
  "component---content-components-button-marketing-mdx": () => import("./../../../content/components/button-marketing.mdx" /* webpackChunkName: "component---content-components-button-marketing-mdx" */),
  "component---content-components-button-mdx": () => import("./../../../content/components/button.mdx" /* webpackChunkName: "component---content-components-button-mdx" */),
  "component---content-components-button-react-deprecated-mdx": () => import("./../../../content/components/button/react/deprecated.mdx" /* webpackChunkName: "component---content-components-button-react-deprecated-mdx" */),
  "component---content-components-checkbox-group-mdx": () => import("./../../../content/components/checkbox-group.mdx" /* webpackChunkName: "component---content-components-checkbox-group-mdx" */),
  "component---content-components-checkbox-mdx": () => import("./../../../content/components/checkbox.mdx" /* webpackChunkName: "component---content-components-checkbox-mdx" */),
  "component---content-components-circle-badge-mdx": () => import("./../../../content/components/circle-badge.mdx" /* webpackChunkName: "component---content-components-circle-badge-mdx" */),
  "component---content-components-circle-octicon-mdx": () => import("./../../../content/components/circle-octicon.mdx" /* webpackChunkName: "component---content-components-circle-octicon-mdx" */),
  "component---content-components-clipboard-copy-button-mdx": () => import("./../../../content/components/clipboard-copy-button.mdx" /* webpackChunkName: "component---content-components-clipboard-copy-button-mdx" */),
  "component---content-components-clipboard-copy-mdx": () => import("./../../../content/components/clipboard-copy.mdx" /* webpackChunkName: "component---content-components-clipboard-copy-mdx" */),
  "component---content-components-close-button-mdx": () => import("./../../../content/components/close-button.mdx" /* webpackChunkName: "component---content-components-close-button-mdx" */),
  "component---content-components-comment-box-mdx": () => import("./../../../content/components/comment-box.mdx" /* webpackChunkName: "component---content-components-comment-box-mdx" */),
  "component---content-components-counter-label-mdx": () => import("./../../../content/components/counter-label.mdx" /* webpackChunkName: "component---content-components-counter-label-mdx" */),
  "component---content-components-data-table-mdx": () => import("./../../../content/components/data-table.mdx" /* webpackChunkName: "component---content-components-data-table-mdx" */),
  "component---content-components-details-mdx": () => import("./../../../content/components/details.mdx" /* webpackChunkName: "component---content-components-details-mdx" */),
  "component---content-components-dialog-mdx": () => import("./../../../content/components/dialog.mdx" /* webpackChunkName: "component---content-components-dialog-mdx" */),
  "component---content-components-form-control-mdx": () => import("./../../../content/components/form-control.mdx" /* webpackChunkName: "component---content-components-form-control-mdx" */),
  "component---content-components-header-mdx": () => import("./../../../content/components/header.mdx" /* webpackChunkName: "component---content-components-header-mdx" */),
  "component---content-components-heading-mdx": () => import("./../../../content/components/heading.mdx" /* webpackChunkName: "component---content-components-heading-mdx" */),
  "component---content-components-hidden-text-expander-mdx": () => import("./../../../content/components/hidden-text-expander.mdx" /* webpackChunkName: "component---content-components-hidden-text-expander-mdx" */),
  "component---content-components-icon-button-mdx": () => import("./../../../content/components/icon-button.mdx" /* webpackChunkName: "component---content-components-icon-button-mdx" */),
  "component---content-components-icon-mdx": () => import("./../../../content/components/icon.mdx" /* webpackChunkName: "component---content-components-icon-mdx" */),
  "component---content-components-image-mdx": () => import("./../../../content/components/image.mdx" /* webpackChunkName: "component---content-components-image-mdx" */),
  "component---content-components-index-mdx": () => import("./../../../content/components/index.mdx" /* webpackChunkName: "component---content-components-index-mdx" */),
  "component---content-components-inline-message-mdx": () => import("./../../../content/components/inline-message.mdx" /* webpackChunkName: "component---content-components-inline-message-mdx" */),
  "component---content-components-label-group-mdx": () => import("./../../../content/components/label-group.mdx" /* webpackChunkName: "component---content-components-label-group-mdx" */),
  "component---content-components-label-mdx": () => import("./../../../content/components/label.mdx" /* webpackChunkName: "component---content-components-label-mdx" */),
  "component---content-components-layout-mdx": () => import("./../../../content/components/layout.mdx" /* webpackChunkName: "component---content-components-layout-mdx" */),
  "component---content-components-link-mdx": () => import("./../../../content/components/link.mdx" /* webpackChunkName: "component---content-components-link-mdx" */),
  "component---content-components-markdown-mdx": () => import("./../../../content/components/markdown.mdx" /* webpackChunkName: "component---content-components-markdown-mdx" */),
  "component---content-components-nav-list-mdx": () => import("./../../../content/components/nav-list.mdx" /* webpackChunkName: "component---content-components-nav-list-mdx" */),
  "component---content-components-octicon-symbols-mdx": () => import("./../../../content/components/octicon-symbols.mdx" /* webpackChunkName: "component---content-components-octicon-symbols-mdx" */),
  "component---content-components-overlay-mdx": () => import("./../../../content/components/overlay.mdx" /* webpackChunkName: "component---content-components-overlay-mdx" */),
  "component---content-components-page-header-mdx": () => import("./../../../content/components/page-header.mdx" /* webpackChunkName: "component---content-components-page-header-mdx" */),
  "component---content-components-page-layout-mdx": () => import("./../../../content/components/page-layout.mdx" /* webpackChunkName: "component---content-components-page-layout-mdx" */),
  "component---content-components-pagehead-mdx": () => import("./../../../content/components/pagehead.mdx" /* webpackChunkName: "component---content-components-pagehead-mdx" */),
  "component---content-components-pagination-mdx": () => import("./../../../content/components/pagination.mdx" /* webpackChunkName: "component---content-components-pagination-mdx" */),
  "component---content-components-pointer-box-mdx": () => import("./../../../content/components/pointer-box.mdx" /* webpackChunkName: "component---content-components-pointer-box-mdx" */),
  "component---content-components-popover-mdx": () => import("./../../../content/components/popover.mdx" /* webpackChunkName: "component---content-components-popover-mdx" */),
  "component---content-components-progress-bar-mdx": () => import("./../../../content/components/progress-bar.mdx" /* webpackChunkName: "component---content-components-progress-bar-mdx" */),
  "component---content-components-radio-group-mdx": () => import("./../../../content/components/radio-group.mdx" /* webpackChunkName: "component---content-components-radio-group-mdx" */),
  "component---content-components-radio-mdx": () => import("./../../../content/components/radio.mdx" /* webpackChunkName: "component---content-components-radio-mdx" */),
  "component---content-components-relative-time-mdx": () => import("./../../../content/components/relative-time.mdx" /* webpackChunkName: "component---content-components-relative-time-mdx" */),
  "component---content-components-segmented-control-mdx": () => import("./../../../content/components/segmented-control.mdx" /* webpackChunkName: "component---content-components-segmented-control-mdx" */),
  "component---content-components-select-mdx": () => import("./../../../content/components/select.mdx" /* webpackChunkName: "component---content-components-select-mdx" */),
  "component---content-components-selectpanel-mdx": () => import("./../../../content/components/selectpanel.mdx" /* webpackChunkName: "component---content-components-selectpanel-mdx" */),
  "component---content-components-skeleton-avatar-mdx": () => import("./../../../content/components/skeleton-avatar.mdx" /* webpackChunkName: "component---content-components-skeleton-avatar-mdx" */),
  "component---content-components-skeleton-box-mdx": () => import("./../../../content/components/skeleton-box.mdx" /* webpackChunkName: "component---content-components-skeleton-box-mdx" */),
  "component---content-components-skeleton-text-mdx": () => import("./../../../content/components/skeleton-text.mdx" /* webpackChunkName: "component---content-components-skeleton-text-mdx" */),
  "component---content-components-spinner-mdx": () => import("./../../../content/components/spinner.mdx" /* webpackChunkName: "component---content-components-spinner-mdx" */),
  "component---content-components-split-page-layout-mdx": () => import("./../../../content/components/split-page-layout.mdx" /* webpackChunkName: "component---content-components-split-page-layout-mdx" */),
  "component---content-components-stack-mdx": () => import("./../../../content/components/stack.mdx" /* webpackChunkName: "component---content-components-stack-mdx" */),
  "component---content-components-state-label-mdx": () => import("./../../../content/components/state-label.mdx" /* webpackChunkName: "component---content-components-state-label-mdx" */),
  "component---content-components-subnav-mdx": () => import("./../../../content/components/subnav.mdx" /* webpackChunkName: "component---content-components-subnav-mdx" */),
  "component---content-components-tab-container-mdx": () => import("./../../../content/components/tab-container.mdx" /* webpackChunkName: "component---content-components-tab-container-mdx" */),
  "component---content-components-tab-nav-mdx": () => import("./../../../content/components/tab-nav.mdx" /* webpackChunkName: "component---content-components-tab-nav-mdx" */),
  "component---content-components-tab-panels-mdx": () => import("./../../../content/components/tab-panels.mdx" /* webpackChunkName: "component---content-components-tab-panels-mdx" */),
  "component---content-components-text-input-mdx": () => import("./../../../content/components/text-input.mdx" /* webpackChunkName: "component---content-components-text-input-mdx" */),
  "component---content-components-text-input-with-tokens-mdx": () => import("./../../../content/components/text-input-with-tokens.mdx" /* webpackChunkName: "component---content-components-text-input-with-tokens-mdx" */),
  "component---content-components-text-mdx": () => import("./../../../content/components/text.mdx" /* webpackChunkName: "component---content-components-text-mdx" */),
  "component---content-components-textarea-mdx": () => import("./../../../content/components/textarea.mdx" /* webpackChunkName: "component---content-components-textarea-mdx" */),
  "component---content-components-timeline-item-mdx": () => import("./../../../content/components/timeline-item.mdx" /* webpackChunkName: "component---content-components-timeline-item-mdx" */),
  "component---content-components-timeline-mdx": () => import("./../../../content/components/timeline.mdx" /* webpackChunkName: "component---content-components-timeline-mdx" */),
  "component---content-components-toggle-switch-mdx": () => import("./../../../content/components/toggle-switch.mdx" /* webpackChunkName: "component---content-components-toggle-switch-mdx" */),
  "component---content-components-token-mdx": () => import("./../../../content/components/token.mdx" /* webpackChunkName: "component---content-components-token-mdx" */),
  "component---content-components-tooltip-mdx": () => import("./../../../content/components/tooltip.mdx" /* webpackChunkName: "component---content-components-tooltip-mdx" */),
  "component---content-components-tree-view-mdx": () => import("./../../../content/components/tree-view.mdx" /* webpackChunkName: "component---content-components-tree-view-mdx" */),
  "component---content-components-truncate-mdx": () => import("./../../../content/components/truncate.mdx" /* webpackChunkName: "component---content-components-truncate-mdx" */),
  "component---content-components-underline-nav-mdx": () => import("./../../../content/components/underline-nav.mdx" /* webpackChunkName: "component---content-components-underline-nav-mdx" */),
  "component---content-components-underline-nav-react-deprecated-mdx": () => import("./../../../content/components/underline-nav/react/deprecated.mdx" /* webpackChunkName: "component---content-components-underline-nav-react-deprecated-mdx" */),
  "component---content-components-underline-panels-mdx": () => import("./../../../content/components/underline-panels.mdx" /* webpackChunkName: "component---content-components-underline-panels-mdx" */),
  "component---content-deprecated-components-box-overlay-mdx": () => import("./../../../content/deprecated-components/box-overlay.mdx" /* webpackChunkName: "component---content-deprecated-components-box-overlay-mdx" */),
  "component---content-deprecated-components-dropdown-mdx": () => import("./../../../content/deprecated-components/dropdown.mdx" /* webpackChunkName: "component---content-deprecated-components-dropdown-mdx" */),
  "component---content-deprecated-components-filtered-search-mdx": () => import("./../../../content/deprecated-components/filtered-search.mdx" /* webpackChunkName: "component---content-deprecated-components-filtered-search-mdx" */),
  "component---content-deprecated-components-flash-mdx": () => import("./../../../content/deprecated-components/flash.mdx" /* webpackChunkName: "component---content-deprecated-components-flash-mdx" */),
  "component---content-deprecated-components-index-mdx": () => import("./../../../content/deprecated-components/index.mdx" /* webpackChunkName: "component---content-deprecated-components-index-mdx" */),
  "component---content-deprecated-components-menu-mdx": () => import("./../../../content/deprecated-components/menu.mdx" /* webpackChunkName: "component---content-deprecated-components-menu-mdx" */),
  "component---content-deprecated-components-select-menu-mdx": () => import("./../../../content/deprecated-components/select-menu.mdx" /* webpackChunkName: "component---content-deprecated-components-select-menu-mdx" */),
  "component---content-deprecated-components-side-nav-index-mdx": () => import("./../../../content/deprecated-components/side-nav/index.mdx" /* webpackChunkName: "component---content-deprecated-components-side-nav-index-mdx" */),
  "component---content-deprecated-components-side-nav-react-latest-mdx": () => import("./../../../content/deprecated-components/side-nav/react/latest.mdx" /* webpackChunkName: "component---content-deprecated-components-side-nav-react-latest-mdx" */),
  "component---content-deprecated-components-toast-mdx": () => import("./../../../content/deprecated-components/toast.mdx" /* webpackChunkName: "component---content-deprecated-components-toast-mdx" */),
  "component---content-foundations-color-accessibility-mdx": () => import("./../../../content/foundations/color/accessibility.mdx" /* webpackChunkName: "component---content-foundations-color-accessibility-mdx" */),
  "component---content-foundations-color-base-scales-mdx": () => import("./../../../content/foundations/color/base-scales.mdx" /* webpackChunkName: "component---content-foundations-color-base-scales-mdx" */),
  "component---content-foundations-color-index-mdx": () => import("./../../../content/foundations/color/index.mdx" /* webpackChunkName: "component---content-foundations-color-index-mdx" */),
  "component---content-foundations-color-overview-mdx": () => import("./../../../content/foundations/color/overview.mdx" /* webpackChunkName: "component---content-foundations-color-overview-mdx" */),
  "component---content-foundations-content-mdx": () => import("./../../../content/foundations/content.mdx" /* webpackChunkName: "component---content-foundations-content-mdx" */),
  "component---content-foundations-css-utilities-animations-mdx": () => import("./../../../content/foundations/css-utilities/animations.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-animations-mdx" */),
  "component---content-foundations-css-utilities-borders-mdx": () => import("./../../../content/foundations/css-utilities/borders.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-borders-mdx" */),
  "component---content-foundations-css-utilities-box-shadow-mdx": () => import("./../../../content/foundations/css-utilities/box-shadow.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-box-shadow-mdx" */),
  "component---content-foundations-css-utilities-colors-mdx": () => import("./../../../content/foundations/css-utilities/colors.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-colors-mdx" */),
  "component---content-foundations-css-utilities-details-mdx": () => import("./../../../content/foundations/css-utilities/details.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-details-mdx" */),
  "component---content-foundations-css-utilities-flexbox-mdx": () => import("./../../../content/foundations/css-utilities/flexbox.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-flexbox-mdx" */),
  "component---content-foundations-css-utilities-getting-started-mdx": () => import("./../../../content/foundations/css-utilities/getting-started.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-getting-started-mdx" */),
  "component---content-foundations-css-utilities-grid-mdx": () => import("./../../../content/foundations/css-utilities/grid.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-grid-mdx" */),
  "component---content-foundations-css-utilities-index-mdx": () => import("./../../../content/foundations/css-utilities/index.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-index-mdx" */),
  "component---content-foundations-css-utilities-layout-mdx": () => import("./../../../content/foundations/css-utilities/layout.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-layout-mdx" */),
  "component---content-foundations-css-utilities-margin-mdx": () => import("./../../../content/foundations/css-utilities/margin.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-margin-mdx" */),
  "component---content-foundations-css-utilities-padding-mdx": () => import("./../../../content/foundations/css-utilities/padding.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-padding-mdx" */),
  "component---content-foundations-css-utilities-typography-mdx": () => import("./../../../content/foundations/css-utilities/typography.mdx" /* webpackChunkName: "component---content-foundations-css-utilities-typography-mdx" */),
  "component---content-foundations-icons-design-guidelines-mdx": () => import("./../../../content/foundations/icons/design-guidelines.mdx" /* webpackChunkName: "component---content-foundations-icons-design-guidelines-mdx" */),
  "component---content-foundations-icons-index-mdx": () => import("./../../../content/foundations/icons/index.mdx" /* webpackChunkName: "component---content-foundations-icons-index-mdx" */),
  "component---content-foundations-icons-octovisuals-mdx": () => import("./../../../content/foundations/icons/octovisuals.mdx" /* webpackChunkName: "component---content-foundations-icons-octovisuals-mdx" */),
  "component---content-foundations-index-mdx": () => import("./../../../content/foundations/index.mdx" /* webpackChunkName: "component---content-foundations-index-mdx" */),
  "component---content-foundations-layout-mdx": () => import("./../../../content/foundations/layout.mdx" /* webpackChunkName: "component---content-foundations-layout-mdx" */),
  "component---content-foundations-primitives-color-mdx": () => import("./../../../content/foundations/primitives/color.mdx" /* webpackChunkName: "component---content-foundations-primitives-color-mdx" */),
  "component---content-foundations-primitives-getting-started-mdx": () => import("./../../../content/foundations/primitives/getting-started.mdx" /* webpackChunkName: "component---content-foundations-primitives-getting-started-mdx" */),
  "component---content-foundations-primitives-index-mdx": () => import("./../../../content/foundations/primitives/index.mdx" /* webpackChunkName: "component---content-foundations-primitives-index-mdx" */),
  "component---content-foundations-primitives-migrating-mdx": () => import("./../../../content/foundations/primitives/migrating.mdx" /* webpackChunkName: "component---content-foundations-primitives-migrating-mdx" */),
  "component---content-foundations-primitives-size-mdx": () => import("./../../../content/foundations/primitives/size.mdx" /* webpackChunkName: "component---content-foundations-primitives-size-mdx" */),
  "component---content-foundations-primitives-token-names-mdx": () => import("./../../../content/foundations/primitives/token-names.mdx" /* webpackChunkName: "component---content-foundations-primitives-token-names-mdx" */),
  "component---content-foundations-primitives-typography-mdx": () => import("./../../../content/foundations/primitives/typography.mdx" /* webpackChunkName: "component---content-foundations-primitives-typography-mdx" */),
  "component---content-foundations-responsive-mdx": () => import("./../../../content/foundations/responsive.mdx" /* webpackChunkName: "component---content-foundations-responsive-mdx" */),
  "component---content-foundations-typography-mdx": () => import("./../../../content/foundations/typography.mdx" /* webpackChunkName: "component---content-foundations-typography-mdx" */),
  "component---content-github-staff-github-shared-components-mdx": () => import("./../../../content/github-staff/github-shared-components.mdx" /* webpackChunkName: "component---content-github-staff-github-shared-components-mdx" */),
  "component---content-github-staff-index-md": () => import("./../../../content/github-staff/index.md" /* webpackChunkName: "component---content-github-staff-index-md" */),
  "component---content-guides-accessibility-accessibility-at-github-mdx": () => import("./../../../content/guides/accessibility/accessibility-at-github.mdx" /* webpackChunkName: "component---content-guides-accessibility-accessibility-at-github-mdx" */),
  "component---content-guides-accessibility-alternative-text-for-images-mdx": () => import("./../../../content/guides/accessibility/alternative-text-for-images.mdx" /* webpackChunkName: "component---content-guides-accessibility-alternative-text-for-images-mdx" */),
  "component---content-guides-accessibility-announcements-mdx": () => import("./../../../content/guides/accessibility/announcements.mdx" /* webpackChunkName: "component---content-guides-accessibility-announcements-mdx" */),
  "component---content-guides-accessibility-color-considerations-mdx": () => import("./../../../content/guides/accessibility/color-considerations.mdx" /* webpackChunkName: "component---content-guides-accessibility-color-considerations-mdx" */),
  "component---content-guides-accessibility-descriptive-buttons-mdx": () => import("./../../../content/guides/accessibility/descriptive-buttons.mdx" /* webpackChunkName: "component---content-guides-accessibility-descriptive-buttons-mdx" */),
  "component---content-guides-accessibility-focus-management-mdx": () => import("./../../../content/guides/accessibility/focus-management.mdx" /* webpackChunkName: "component---content-guides-accessibility-focus-management-mdx" */),
  "component---content-guides-accessibility-guidelines-mdx": () => import("./../../../content/guides/accessibility/guidelines.mdx" /* webpackChunkName: "component---content-guides-accessibility-guidelines-mdx" */),
  "component---content-guides-accessibility-headings-mdx": () => import("./../../../content/guides/accessibility/headings.mdx" /* webpackChunkName: "component---content-guides-accessibility-headings-mdx" */),
  "component---content-guides-accessibility-index-mdx": () => import("./../../../content/guides/accessibility/index.mdx" /* webpackChunkName: "component---content-guides-accessibility-index-mdx" */),
  "component---content-guides-accessibility-links-mdx": () => import("./../../../content/guides/accessibility/links.mdx" /* webpackChunkName: "component---content-guides-accessibility-links-mdx" */),
  "component---content-guides-accessibility-placeholders-mdx": () => import("./../../../content/guides/accessibility/placeholders.mdx" /* webpackChunkName: "component---content-guides-accessibility-placeholders-mdx" */),
  "component---content-guides-accessibility-semantic-html-mdx": () => import("./../../../content/guides/accessibility/semantic-html.mdx" /* webpackChunkName: "component---content-guides-accessibility-semantic-html-mdx" */),
  "component---content-guides-accessibility-text-resize-and-respacing-mdx": () => import("./../../../content/guides/accessibility/text-resize-and-respacing.mdx" /* webpackChunkName: "component---content-guides-accessibility-text-resize-and-respacing-mdx" */),
  "component---content-guides-accessibility-tools-mdx": () => import("./../../../content/guides/accessibility/tools.mdx" /* webpackChunkName: "component---content-guides-accessibility-tools-mdx" */),
  "component---content-guides-accessibility-tooltip-alternatives-mdx": () => import("./../../../content/guides/accessibility/tooltip-alternatives.mdx" /* webpackChunkName: "component---content-guides-accessibility-tooltip-alternatives-mdx" */),
  "component---content-guides-component-lifecycle-mdx": () => import("./../../../content/guides/component-lifecycle.mdx" /* webpackChunkName: "component---content-guides-component-lifecycle-mdx" */),
  "component---content-guides-contribute-adding-new-components-mdx": () => import("./../../../content/guides/contribute/adding-new-components.mdx" /* webpackChunkName: "component---content-guides-contribute-adding-new-components-mdx" */),
  "component---content-guides-contribute-design-mdx": () => import("./../../../content/guides/contribute/design.mdx" /* webpackChunkName: "component---content-guides-contribute-design-mdx" */),
  "component---content-guides-contribute-documentation-mdx": () => import("./../../../content/guides/contribute/documentation.mdx" /* webpackChunkName: "component---content-guides-contribute-documentation-mdx" */),
  "component---content-guides-contribute-handling-new-patterns-mdx": () => import("./../../../content/guides/contribute/handling-new-patterns.mdx" /* webpackChunkName: "component---content-guides-contribute-handling-new-patterns-mdx" */),
  "component---content-guides-contribute-how-to-contribute-mdx": () => import("./../../../content/guides/contribute/how-to-contribute.mdx" /* webpackChunkName: "component---content-guides-contribute-how-to-contribute-mdx" */),
  "component---content-guides-contribute-index-mdx": () => import("./../../../content/guides/contribute/index.mdx" /* webpackChunkName: "component---content-guides-contribute-index-mdx" */),
  "component---content-guides-figma-contribute-mdx": () => import("./../../../content/guides/figma/contribute.mdx" /* webpackChunkName: "component---content-guides-figma-contribute-mdx" */),
  "component---content-guides-figma-getting-started-mdx": () => import("./../../../content/guides/figma/getting-started.mdx" /* webpackChunkName: "component---content-guides-figma-getting-started-mdx" */),
  "component---content-guides-figma-index-mdx": () => import("./../../../content/guides/figma/index.mdx" /* webpackChunkName: "component---content-guides-figma-index-mdx" */),
  "component---content-guides-index-mdx": () => import("./../../../content/guides/index.mdx" /* webpackChunkName: "component---content-guides-index-mdx" */),
  "component---content-guides-introduction-mdx": () => import("./../../../content/guides/introduction.mdx" /* webpackChunkName: "component---content-guides-introduction-mdx" */),
  "component---content-guides-rails-index-mdx": () => import("./../../../content/guides/rails/index.mdx" /* webpackChunkName: "component---content-guides-rails-index-mdx" */),
  "component---content-guides-rails-migration-guides-index-mdx": () => import("./../../../content/guides/rails/migration-guides/index.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-index-mdx" */),
  "component---content-guides-rails-migration-guides-primer-button-component-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-button-component.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-button-component-mdx" */),
  "component---content-guides-rails-migration-guides-primer-css-select-menu-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-css-select-menu.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-css-select-menu-mdx" */),
  "component---content-guides-rails-migration-guides-primer-css-tooltipped-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-css-tooltipped.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-css-tooltipped-mdx" */),
  "component---content-guides-rails-migration-guides-primer-dialog-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-dialog.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-dialog-mdx" */),
  "component---content-guides-rails-migration-guides-primer-layout-component-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-layout-component.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-layout-component-mdx" */),
  "component---content-guides-rails-migration-guides-primer-local-time-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-local-time.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-local-time-mdx" */),
  "component---content-guides-rails-migration-guides-primer-time-ago-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-time-ago.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-time-ago-mdx" */),
  "component---content-guides-rails-migration-guides-primer-truncate-mdx": () => import("./../../../content/guides/rails/migration-guides/primer-truncate.mdx" /* webpackChunkName: "component---content-guides-rails-migration-guides-primer-truncate-mdx" */),
  "component---content-guides-react-core-concepts-mdx": () => import("./../../../content/guides/react/core-concepts.mdx" /* webpackChunkName: "component---content-guides-react-core-concepts-mdx" */),
  "component---content-guides-react-hooks-mdx": () => import("./../../../content/guides/react/hooks.mdx" /* webpackChunkName: "component---content-guides-react-hooks-mdx" */),
  "component---content-guides-react-hooks-use-anchored-position-mdx": () => import("./../../../content/guides/react/hooks/use-anchored-position.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-anchored-position-mdx" */),
  "component---content-guides-react-hooks-use-focus-trap-mdx": () => import("./../../../content/guides/react/hooks/use-focus-trap.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-focus-trap-mdx" */),
  "component---content-guides-react-hooks-use-focus-zone-mdx": () => import("./../../../content/guides/react/hooks/use-focus-zone.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-focus-zone-mdx" */),
  "component---content-guides-react-hooks-use-on-escape-press-mdx": () => import("./../../../content/guides/react/hooks/use-on-escape-press.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-on-escape-press-mdx" */),
  "component---content-guides-react-hooks-use-on-outside-click-mdx": () => import("./../../../content/guides/react/hooks/use-on-outside-click.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-on-outside-click-mdx" */),
  "component---content-guides-react-hooks-use-open-and-close-focus-mdx": () => import("./../../../content/guides/react/hooks/use-open-and-close-focus.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-open-and-close-focus-mdx" */),
  "component---content-guides-react-hooks-use-overlay-mdx": () => import("./../../../content/guides/react/hooks/use-overlay.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-overlay-mdx" */),
  "component---content-guides-react-hooks-use-safe-timeout-mdx": () => import("./../../../content/guides/react/hooks/use-safe-timeout.mdx" /* webpackChunkName: "component---content-guides-react-hooks-use-safe-timeout-mdx" */),
  "component---content-guides-react-index-mdx": () => import("./../../../content/guides/react/index.mdx" /* webpackChunkName: "component---content-guides-react-index-mdx" */),
  "component---content-guides-react-linting-mdx": () => import("./../../../content/guides/react/linting.mdx" /* webpackChunkName: "component---content-guides-react-linting-mdx" */),
  "component---content-guides-react-overriding-styles-mdx": () => import("./../../../content/guides/react/overriding-styles.mdx" /* webpackChunkName: "component---content-guides-react-overriding-styles-mdx" */),
  "component---content-guides-react-philosophy-mdx": () => import("./../../../content/guides/react/philosophy.mdx" /* webpackChunkName: "component---content-guides-react-philosophy-mdx" */),
  "component---content-guides-react-system-props-mdx": () => import("./../../../content/guides/react/system-props.mdx" /* webpackChunkName: "component---content-guides-react-system-props-mdx" */),
  "component---content-guides-react-theme-reference-mdx": () => import("./../../../content/guides/react/theme-reference.mdx" /* webpackChunkName: "component---content-guides-react-theme-reference-mdx" */),
  "component---content-guides-react-theming-mdx": () => import("./../../../content/guides/react/theming.mdx" /* webpackChunkName: "component---content-guides-react-theming-mdx" */),
  "component---content-guides-status-mdx": () => import("./../../../content/guides/status.mdx" /* webpackChunkName: "component---content-guides-status-mdx" */),
  "component---content-index-mdx": () => import("./../../../content/index.mdx" /* webpackChunkName: "component---content-index-mdx" */),
  "component---content-native-cli-components-mdx": () => import("./../../../content/native/cli/components.mdx" /* webpackChunkName: "component---content-native-cli-components-mdx" */),
  "component---content-native-cli-foundations-mdx": () => import("./../../../content/native/cli/foundations.mdx" /* webpackChunkName: "component---content-native-cli-foundations-mdx" */),
  "component---content-native-cli-getting-started-mdx": () => import("./../../../content/native/cli/getting-started.mdx" /* webpackChunkName: "component---content-native-cli-getting-started-mdx" */),
  "component---content-native-cli-index-mdx": () => import("./../../../content/native/cli/index.mdx" /* webpackChunkName: "component---content-native-cli-index-mdx" */),
  "component---content-native-desktop-foundations-mdx": () => import("./../../../content/native/desktop/foundations.mdx" /* webpackChunkName: "component---content-native-desktop-foundations-mdx" */),
  "component---content-native-desktop-getting-started-mdx": () => import("./../../../content/native/desktop/getting-started.mdx" /* webpackChunkName: "component---content-native-desktop-getting-started-mdx" */),
  "component---content-native-desktop-index-mdx": () => import("./../../../content/native/desktop/index.mdx" /* webpackChunkName: "component---content-native-desktop-index-mdx" */),
  "component---content-native-index-mdx": () => import("./../../../content/native/index.mdx" /* webpackChunkName: "component---content-native-index-mdx" */),
  "component---content-native-mobile-foundations-mdx": () => import("./../../../content/native/mobile/foundations.mdx" /* webpackChunkName: "component---content-native-mobile-foundations-mdx" */),
  "component---content-native-mobile-index-mdx": () => import("./../../../content/native/mobile/index.mdx" /* webpackChunkName: "component---content-native-mobile-index-mdx" */),
  "component---content-native-mobile-platforms-mdx": () => import("./../../../content/native/mobile/platforms.mdx" /* webpackChunkName: "component---content-native-mobile-platforms-mdx" */),
  "component---content-ui-patterns-data-visualization-mdx": () => import("./../../../content/ui-patterns/data-visualization.mdx" /* webpackChunkName: "component---content-ui-patterns-data-visualization-mdx" */),
  "component---content-ui-patterns-degraded-experiences-mdx": () => import("./../../../content/ui-patterns/degraded-experiences.mdx" /* webpackChunkName: "component---content-ui-patterns-degraded-experiences-mdx" */),
  "component---content-ui-patterns-empty-states-mdx": () => import("./../../../content/ui-patterns/empty-states.mdx" /* webpackChunkName: "component---content-ui-patterns-empty-states-mdx" */),
  "component---content-ui-patterns-feature-onboarding-mdx": () => import("./../../../content/ui-patterns/feature-onboarding.mdx" /* webpackChunkName: "component---content-ui-patterns-feature-onboarding-mdx" */),
  "component---content-ui-patterns-forms-overview-mdx": () => import("./../../../content/ui-patterns/forms/overview.mdx" /* webpackChunkName: "component---content-ui-patterns-forms-overview-mdx" */),
  "component---content-ui-patterns-forms-rails-mdx": () => import("./../../../content/ui-patterns/forms/rails.mdx" /* webpackChunkName: "component---content-ui-patterns-forms-rails-mdx" */),
  "component---content-ui-patterns-forms-react-mdx": () => import("./../../../content/ui-patterns/forms/react.mdx" /* webpackChunkName: "component---content-ui-patterns-forms-react-mdx" */),
  "component---content-ui-patterns-index-mdx": () => import("./../../../content/ui-patterns/index.mdx" /* webpackChunkName: "component---content-ui-patterns-index-mdx" */),
  "component---content-ui-patterns-loading-mdx": () => import("./../../../content/ui-patterns/loading.mdx" /* webpackChunkName: "component---content-ui-patterns-loading-mdx" */),
  "component---content-ui-patterns-navigation-mdx": () => import("./../../../content/ui-patterns/navigation.mdx" /* webpackChunkName: "component---content-ui-patterns-navigation-mdx" */),
  "component---content-ui-patterns-notification-messaging-mdx": () => import("./../../../content/ui-patterns/notification-messaging.mdx" /* webpackChunkName: "component---content-ui-patterns-notification-messaging-mdx" */),
  "component---content-ui-patterns-progressive-disclosure-mdx": () => import("./../../../content/ui-patterns/progressive-disclosure.mdx" /* webpackChunkName: "component---content-ui-patterns-progressive-disclosure-mdx" */),
  "component---content-ui-patterns-saving-mdx": () => import("./../../../content/ui-patterns/saving.mdx" /* webpackChunkName: "component---content-ui-patterns-saving-mdx" */),
  "component---src-layouts-css-component-layout-tsx": () => import("./../../../src/layouts/css-component-layout.tsx" /* webpackChunkName: "component---src-layouts-css-component-layout-tsx" */),
  "component---src-layouts-figma-component-layout-tsx": () => import("./../../../src/layouts/figma-component-layout.tsx" /* webpackChunkName: "component---src-layouts-figma-component-layout-tsx" */),
  "component---src-layouts-icon-page-js": () => import("./../../../src/layouts/icon-page.js" /* webpackChunkName: "component---src-layouts-icon-page-js" */),
  "component---src-layouts-rails-component-layout-tsx": () => import("./../../../src/layouts/rails-component-layout.tsx" /* webpackChunkName: "component---src-layouts-rails-component-layout-tsx" */),
  "component---src-layouts-react-component-layout-tsx": () => import("./../../../src/layouts/react-component-layout.tsx" /* webpackChunkName: "component---src-layouts-react-component-layout-tsx" */),
  "component---src-layouts-redirect-layout-tsx": () => import("./../../../src/layouts/redirect-layout.tsx" /* webpackChunkName: "component---src-layouts-redirect-layout-tsx" */)
}

