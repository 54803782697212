import { HeroLayout } from '@primer/gatsby-theme-doctocat';
import { Text, Link, Box, Heading } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';
export default {
  HeroLayout,
  Text,
  Link,
  Box,
  Heading,
  GatsbyLink,
  React
};