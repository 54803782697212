import { Box, Text, Link } from '@primer/react';
import { LinkExternalIcon, DownloadIcon } from '@primer/octicons-react';
import OctovisualsBanner from "../../../../content/foundations/icons/images/octovisuals.png";
import "../../../../content/foundations/icons/octovisuals.css";
import * as React from 'react';
export default {
  Box,
  Text,
  Link,
  LinkExternalIcon,
  DownloadIcon,
  OctovisualsBanner,
  React
};