import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import { Box } from '@primer/react';
import { Caption } from '@primer/gatsby-theme-doctocat';
import * as React from 'react';
export default {
  ComponentLayout,
  AccessibilityLink,
  Box,
  Caption,
  React
};