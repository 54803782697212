import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import * as React from 'react';
export default {
  Box,
  Text,
  ComponentLayout,
  AccessibilityLink,
  React
};