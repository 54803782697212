import ButtonTextOnly from "../../../../src/images/accessibility/color-considerations/button-text-only.png";
import ButtonFunctionalIcon from "../../../../src/images/accessibility/color-considerations/button-functional-icon.png";
import ButtonDecorativeIcon from "../../../../src/images/accessibility/color-considerations/button-decorative-icon.png";
import InputBorderContrast from "../../../../src/images/accessibility/color-considerations/input-border-contrast.png";
import InputSelectionState from "../../../../src/images/accessibility/color-considerations/input-selection-state.png";
import InputText from "../../../../src/images/accessibility/color-considerations/input-text.png";
import InputValidationState from "../../../../src/images/accessibility/color-considerations/input-validation-state.png";
import SearchCollapsed from "../../../../src/images/accessibility/color-considerations/search-collapsed.png";
import SearchExpanded from "../../../../src/images/accessibility/color-considerations/search-expanded.png";
import ThemePicker from "../../../../src/images/accessibility/color-considerations/theme-picker.png";
import * as React from 'react';
export default {
  ButtonTextOnly,
  ButtonFunctionalIcon,
  ButtonDecorativeIcon,
  InputBorderContrast,
  InputSelectionState,
  InputText,
  InputValidationState,
  SearchCollapsed,
  SearchExpanded,
  ThemePicker,
  React
};