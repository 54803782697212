import createBranchImg from "../../../../src/images/figma/create-branch.png";
import createBranchDialogImg from "../../../../src/images/figma/create-branch-dialog.png";
import requestReviewImg from "../../../../src/images/figma/request-review.png";
import mergeDialogImg from "../../../../src/images/figma/merge-dialog.png";
import requestDialogImg from "../../../../src/images/figma/request-dialog.png";
import * as React from 'react';
export default {
  createBranchImg,
  createBranchDialogImg,
  requestReviewImg,
  mergeDialogImg,
  requestDialogImg,
  React
};