import { Box } from '@primer/react';
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
import doLinks31 from "../../../../src/images/accessibility/links/do-links-3_1.png";
import dontLinks31 from "../../../../src/images/accessibility/links/dont-links-3_1.png";
import doLinksIcon from "../../../../src/images/accessibility/links/do-links-icon-or-shape.png";
import dontLinksIcon from "../../../../src/images/accessibility/links/dont-links-icon-or-shape.png";
import doLinksUnderlined from "../../../../src/images/accessibility/links/do-links-underlined.png";
import dontLinksUnderlined from "../../../../src/images/accessibility/links/dont-links-underlined.png";
import * as React from 'react';
export default {
  Box,
  Code,
  doLinks31,
  dontLinks31,
  doLinksIcon,
  dontLinksIcon,
  doLinksUnderlined,
  dontLinksUnderlined,
  React
};