import { Box } from '@primer/react';
import { InfoIcon, CheckCircleIcon, StopIcon, AlertIcon } from '@primer/octicons-react';
import * as React from 'react';
export default {
  Box,
  InfoIcon,
  CheckCircleIcon,
  StopIcon,
  AlertIcon,
  React
};