import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { ComponentPageNav } from '~/src/components/component-page-nav';
import * as React from 'react';
export default {
  Link,
  Text,
  GatsbyLink,
  ComponentPageNav,
  React
};