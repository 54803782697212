import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import { Box, Heading } from '@primer/react';
import * as React from 'react';
export default {
  ComponentLayout,
  AccessibilityLink,
  Box,
  Heading,
  React
};