import { DoDontContainer, Do, Dont, Caption } from '@primer/gatsby-theme-doctocat';
import ScrollToAnchor from '~/src/components/scroll-to-anchor';
import * as React from 'react';
export default {
  DoDontContainer,
  Do,
  Dont,
  Caption,
  ScrollToAnchor,
  React
};