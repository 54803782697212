import { Box, Heading } from '@primer/react';
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
import FormsLayout from '~/src/layouts/forms-layout';
import * as React from 'react';
export default {
  Box,
  Heading,
  Code,
  FormsLayout,
  React
};