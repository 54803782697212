import { Box, Text } from '@primer/react';
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
import * as React from 'react';
export default {
  Box,
  Text,
  Code,
  ComponentLayout,
  AccessibilityLink,
  React
};