import PropsList from '~/src/components/props-list';
import { COMMON, LAYOUT, BORDER, TYPOGRAPHY } from '~/src/components/react-constants';
import * as React from 'react';
export default {
  PropsList,
  COMMON,
  LAYOUT,
  BORDER,
  TYPOGRAPHY,
  React
};