import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';
export default {
  ComponentLayout,
  Link,
  Text,
  GatsbyLink,
  React
};