const teamContent = [
  {
    name: 'Marie Lucca',
    handle: 'francinelucca',
    title: 'Software Engineer',
    questionOne:
      `I was enticed by the prospect of doing work that is impactful at a company-wide scale and beyond, and the striveness for excellence that performing at such a critical, very low-error-tolerance environment entails. I am always very proud to see the results of my team's and my hard work materialized in beautiful, useful applications deployed worldwide. I also appreciate the opportunity to work very closely with design while still wearing a developer hat.`,
    questionTwo: `I've had the opportunity to work and be mentored by some truly amazing folks. From managers, technology leaders, software architects, professors turned colleagues, and more. I appreciate every professional I have and will interface with along my journey and take a small piece of them with me wherever I go. 
    
    Honorable mentions go to [Sergio Lopez](https://www.linkedin.com/in/sergio-l%C3%B3pez-90725288), the college professor that took me under his wing and beared with me as I broke production more times than I'd be willing to admit, closely followed by [Joe Harvey](https://www.linkedin.com/in/joseph-harvey-ny), who taught me the difference between software development and engineering.`,
    favoriteTools: 'VSCode, GitHub, TypeScript, React, middle-of-the-day naps, high-speed internet, and, most importantly, a mini can of Coke Zero ',
    cssFeature: 'calc(<wild-math-equation-here>)',
    avatar: 'https://avatars.githubusercontent.com/francinelucca?s=512',
    gif: 'https://raw.githubusercontent.com/francinelucca/francinelucca/assets/dog-in-bed.gif',
    alumni: false,
  },
  {
    name: 'Josh Farrant',
    handle: 'joshfarrant',
    title: 'Senior Software Engineer',
    cssFeature: 'CSS nesting',
    questionOne: `In many ways, the creation of [Storybook](https://storybook.js.org/). Version 3 coincided with the start of my second React project and started me thinking about how I might be able to design components once and share them between the two apps, leading to my first basic design system. Over the years I've focused more of my time on design systems, refined my skills, and fallen deeper down the rabbit hole.`,
    questionTwo: `[Brad Frost](https://bradfrost.com/)'s work on Atomic Design and his vision for a Global Design System have significantly informed how I think about design systems. [Dave Rupert](https://daverupert.com/) has opened my eyes to how much is possible with Web Components, [Bartosz Ciechanowski](https://ciechanow.ski/) showcases exceptional writing and storytelling on the web, and I find continuous inspiration from [Una Kravets](https://una.im/), [Jen Simmons](https://jensimmons.com/), and [Adam Argyle](https://nerdy.dev/), as well as the superior Joshs; [Collinsworth](https://joshcollinsworth.com/) and [Comeau](https://www.joshwcomeau.com/).`,
    favoriteTools: 'Raycast, Obsidian, TypeScript, Web Components, V60, ZSA Moonlander, OP-1',
    avatar: 'https://avatars.githubusercontent.com/joshfarrant?s=512',
    gif: 'https://github.com/joshfarrant/joshfarrant/blob/master/assets/img/jameshoffmann-no.gif?raw=true',
    alumni: false,
  },
  {
    name: 'Jessica Kuo',
    title: 'Primer Systems Designer Intern',
    handle: 'jesskuo4',
    cssFeature: `animation: jump 1s infinite`,
    questionOne: `Studying engineering and design, I enjoy collaborating with designers and engineers from various teams to ensure that the design system remains adaptive in addressing evolving needs. Working on design systems provides a unique opportunity to shape the foundational elements that drive consistency, branding, and cohesive user experiences across an entire organization. In addition, it allows me to learn how to look at our products and experiences at a systems-first, future-proof level.`,
    questionTwo: `I'm immensely grateful for the online design community, a nurturing space where designers from all walks of life share their knowledge with the next generation— [Steve Jobs](https://www.apple.com/stevejobs/), [Julie Zhuo](https://www.juliezhuo.com/), [Ethan Eismann](https://twitter.com/eeismann) and many others. This collective effort continuously inspires my own creative journey to create accessible, beautiful, and seamless experiences.

    I want to express my sincerest gratitude to the design team at GitHub, who mentored and supported me in discoverying my passion for design systems: [Natalya Shelburne](http://www.artist-developer.com/), [Katie Langerman](https://www.langermank.com/), and [Cole Bemis](https://colebemis.com/). Ultimately, it is the collective wisdom, creativity, and collaborations that fuel my joy in designing.`,
    favoriteTools: `Copilot, Figma, Rewatch, GitHub, Youtube, Twitter, Primer.style`,
    avatar: 'https://avatars.githubusercontent.com/u/131988618?v=4',
    gif: 'https://github.com/jesskuo4/jesskuo4/assets/131988618/9671cd85-5e23-4a1f-ba02-0dee1219b927',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Tyler Jones',
    title: 'Accessibility Software Engineer',
    handle: 'tylerjdev',
    questionOne: `Being able to utilize the same components throughout different applications is what I love about design systems. I believe it can be a powerful tool to keep consistency, especially in regards to accessibility. I also love the challenges that a design system brings, along with the rewards for the developers that utilize it.`,
    questionTwo: `I've learned a ton from my colleagues in the past. Stephen, Kyle, Michael, John, Trevor, and so many others. I'm also a big fan of [Adrian Roselli](https://adrianroselli.com/), [Sara Soueidan](https://www.sarasoueidan.com/), [Marcy Sutton](https://marcysutton.com/), and all the others who helped me gain more insights into accessibility as a whole.`,
    favoriteTools: `VS Code, NVDA, GitHub, CodePen, my notebook`,
    avatar: 'https://avatars.githubusercontent.com/u/26746305?v=4',
    gif: 'https://camo.githubusercontent.com/b50b82a588f51b30cada6475a69147bfe63dc52aae964a80e9791490e85eda3e/68747470733a2f2f692e67697068792e636f6d2f35464b4e6a6d385275333237752e676966',
    alumni: false,
  },
  {
    name: 'Rayta van Rijswijk',
    title: 'Engineering Manager',
    handle: 'raytalks',
    questionOne: `Having worked in and with several different software development teams before, I've learned it takes more than a process to deliver fast, reliable, high performant, quality products.

  Combining design and engineering on a stable system, while creating the most astonishing, accessible experience for our end users and developers is pure joy.`,
    questionTwo: `I was fortunate to have had great mentors and colleagues who supported me througout my career and taught me the nitty gritty of programming. I'm a big fan of Katrina Owen, co-founder and director of [Exercism](https://exercism.org/). I adore the work of management leaders like [Camille Fournier](https://twitter.com/skamille),
      and I devour books where I can learn about people like [Erin Meyer's](https://erinmeyer.com/books/the-culture-map/) The Culture Map.`,
    favoriteTools: `Obsidian, Slack, Raycast, iTerm, hand lotion`,
    avatar: 'https://avatars.githubusercontent.com/u/13314317?s=400&u=98f8f63ae206c512f30f8363c2b5473201eda16d&v=4',
    gif: 'https://user-images.githubusercontent.com/13314317/209345245-5ef0b194-ea9f-476a-88e2-0b6400cac9bf.gif',
    alumni: false,
  },
  {
    name: 'Josh Bowden',
    handle: 'JoshBowdenConcepts',
    title: 'Software Engineer',
    questionOne:
      'Since the beginning of my software career I have heard the common phrase "Don\'t repeat yourself" or DRY programming as some people might know it. In following this practice I found myself designing React components to be reused. This interest in reusable code paired with my fascination with design and usability led me to design systems.',
    questionTwo:
      'Most of the influential inspiration has been from my co-workers at each of the places I have worked at over the years. The amount of information that I have collectively gained from them is incalculable.\n\nIn the accessibility space I have learned quite a bit from [Derek Featherstone](http://simplyaccessible.com/article/author/feather/). Orienting your mind to look through a lens of more inclusive design has changed my perspective on both design and engineering.\n\nMy favorite design system podcast over the years has been [designsystemspodcast](https://www.designsystemspodcast.com/). Not only do they have really interesting takes on different companies navigating the design system space, but they are good at giving useful information that can be directly applied to your own organization.',
    favoriteTools: 'VSCode, GitHub, Figma, Spotify, Daily Dev',
    cssFeature: 'grid-template-areas',
    avatar: 'https://avatars.githubusercontent.com/u/31220082?v=4',
    gif: 'https://user-images.githubusercontent.com/31220082/186910263-f0d4c470-a8ea-472e-aa6e-f41a585620ee.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Josh Black',
    handle: 'joshblack',
    title: 'Software Engineer',
    questionOne:
      'I love how they foster an environment and culture of continuously learning, growing, and connecting within a community. Being able to support people in their day-to-day work is so rewarding to me, especially when these tools or resources help to improve the access of everything we make.',
    questionTwo: `I've always loved watching talks by [Alan Kay](https://en.wikipedia.org/wiki/Alan_Kay). The way he thinks about the future and inventing for it makes me so hopeful and excited.

  I also enjoy revisiting talks from [Sebastian Markbåge](https://twitter.com/sebmarkbage), [Nicholas Matsakis](https://twitter.com/nikomatsakis), and [Sandi Metz](https://twitter.com/sandimetz). I learn something new each time as I hear them talk about their approach to writing code and incrementally evolving complex systems over time.

  When it comes to accessibility, I am forever grateful for all the resources, writing, and presentations from [Adrian Roselli](https://twitter.com/aardrian), [Sarah Higley](https://twitter.com/codingchaos), and [Scott O'Hara](https://twitter.com/scottohara). Their work has been so influential to my own.

  Finally, when it comes to building and supporting teams I've loved going through resources from [Julie Zhou](https://twitter.com/joulee), [Lara Hogan](https://twitter.com/lara_hogan), and [Will Larson](https://twitter.com/lethain).
      `,
    favoriteTools: 'NeoVim, GitHub, ARIA APG, coffee, Figma, Spotify',
    cssFeature: 'var(--css-custom-properties)',
    avatar: 'https://user-images.githubusercontent.com/3901764/184181622-30111cc8-7e66-4356-a606-40be07b6a27c.jpg',
    gif: 'https://user-images.githubusercontent.com/3901764/184182200-20d9a3cb-2cae-45cf-bad4-1ac2e123ccc2.gif',
    alumni: false,
  },
  {
    name: 'Lukas Oppermann',
    handle: 'lukasoppermann',
    title: 'Systems Designer',
    questionOne:
      'As a product designer I always focused on creating a consistent experience. When I started as a designer, this meant building style guides and meticulously checking every screen before it was shipped. Over time this turned into design systems with coded components, design tokens, patterns and guidelines.',
    questionTwo:
      'I learned one of my most valuable lesson from Michael Beirut who is so modest and open about his mistakes. This helped me realise that being vulnerable is a strength that accelerates personal growth. [James Clear](https://twitter.com/jamesclear), with his book “atomic habits” taught me that achieving big goals is done by taking one small step after another. I have also learned much from my friends and colleagues. I find that everybody has something to teach if you are willing to learn.',
    favoriteTools: 'VS Code, Figma, GitHub, Notion, Spotify, Raycast, Fig',
    avatar: 'https://avatars.githubusercontent.com/u/813754?v=4',
    gif: 'https://user-images.githubusercontent.com/813754/183907598-d18d505d-56fd-4af4-8d9c-0f723bb05e60.gif',
    alumni: false,
  },
  {
    name: 'Alexis Lucio',
    handle: 'alexislucio',
    title: 'Senior Accessibility Designer',
    questionOne:
      'Design systems were initially described to me as “a centralized hub for designers and developers to build faster, more efficiently, and consistent.” That made a lot of sense and it’s easier said that done. When I started to work in web accessibility (a11y), I realized the biggest impact I could make on a product was through an accessible design system versus one-off product fixes, which only increased my interest.',
    questionTwo:
      'I am extremely grateful to have a series of mentors that have created brave spaces for me as someone in tech with multiple marginalized identities. Most recently, I’ve been inspired by the compassion and optimism of the a11y community, and younger generations who realize that web accessibility is civil and humane right versus an afterthought.',
    favoriteTools: 'Figma, Inspect, Colour Contrast Analyzer (CCA), Slack, Spotify, dance breaks',
    avatar: 'https://avatars.githubusercontent.com/u/19936163?v=4',
    gif: 'https://user-images.githubusercontent.com/19936163/192598673-f924080b-585a-44f1-bdcb-c9c81b3aa1bd.gif',
    alumni: false,
  },
  {
    name: 'Eric Bailey',
    handle: 'ericwbailey',
    title: 'Senior Accessibility Designer',
    questionOne:
      'Design systems help ensure rock-solid, accessible patterns get distributed and updated at scale. This is vital to help ensuring digital experiences such as GitHub are usable by all.',
    questionTwo:
      'Too many to name here. The digital accessibility space is full of kind, intelligent, passionate people, and I owe much of my growth and career to their collective knowledge.',
    favoriteTools:
      'Figma, VS Code, Slack, VoiceOver, NVDA, Dropbox Paper, Obsidian, and copious amounts of iced coffee.',
    avatar: 'https://avatars.githubusercontent.com/u/634191?v=4',
    gif: 'https://user-images.githubusercontent.com/634191/192597827-ff2b2b43-c0d9-4c40-bf0c-7811acf22d78.gif',
    alumni: false,
  },
  {
    name: 'Armağan Ersöz',
    handle: 'broccolinisoup',
    title: 'Software Engineer',
    questionOne:
      'After seeing how reusable component libraries can solve so many UX and DX problems, I continued to dig into the space and started learning about design systems and I was fascinated by the concept since then! I also love focusing on building individual pieces and making them accessible with a systematic approach so that other teams only worry about their domain-specific problems.',
    questionTwo: `[Alla Kholmatova’s](https://www.craftui.com/) [Design Systems](https://shop.smashingmagazine.com/products/design-systems-by-alla-kholmatova) book was one of the first resources I came across in this area and it broadened my understanding greatly. The work [Dan Mall](https://v4.danmall.com/), [Brad Frost](https://bradfrost.com/) and [Nathan Curtis](https://medium.com/@nathanacurtis) have been doing in the design systems space helped me start putting things in action along with some of my favourite platforms and they are [Invision Design Better](https://www.designbetter.co/), [IBM Carbon Design System](https://carbondesignsystem.com/), [Smashing Magazine](https://smashingmagazine.com/). There are also quite a few individuals I enjoy learning from and some of them are [Joshua Comeau](https://www.joshwcomeau.com/), [Sara Soueidan](https://sarasoueidan.com/), [Marcy Sutton](https://marcysutton.com/), [Stephanie Eckles](https://thinkdobecreate.com/) and of course the amazing Primer team 💙`,
    favoriteTools: 'VS Code, Notion, GitHub, Slack, pen and paper, Hario V60 brewing kit, books',
    avatar: 'https://avatars.githubusercontent.com/u/1446503?v=4',
    gif: 'https://user-images.githubusercontent.com/1446503/194750845-901a2a63-b2b2-46d8-95dd-8ac0cca4efc1.gif',
    alumni: false,
  },
  {
    name: 'Josep Martins',
    handle: 'josepmartins',
    title: 'Systems Designer',
    questionOne:
      'It naturally occurred while working on startup projects with limited resources, striving to enhance our productivity while avoiding repeated activities and inconsistent UI. Learning about OOCSS, SMACSS, or BEM methodologies pushed and drew me in the direction of a system thinking strategy.',
    questionTwo:
      'From all the co-workers and friends I’ve been lucky to work with: [Hayk An](https://github.com/hihayk), [Victoria Nikitina](https://twitter.com/killnicole), [Daniel Guillan](https://github.com/danielguillan), [Javier Sanchez](https://github.com/vieron), and from all of the folks who share their knowledge in the design and development space: [Lea Verou](https://github.com/LeaVerou), [Harry Roberts](https://github.com/csswizardry), [Sara Soueidan](https://github.com/SaraSoueidan), [Benjamin de Cock](https://github.com/bendc), and [Brent Jackson](https://github.com/jxnblk) to name a few.',
    favoriteTools: 'VS Code, Notes, Figma, GitHub, Vercel, Spotify',
    avatar: 'https://user-images.githubusercontent.com/912236/176458039-9c71869d-66c6-4329-a0e7-c5c7ddcdd722.jpg',
    gif: 'https://user-images.githubusercontent.com/912236/176458822-7710da14-9036-478b-807d-733922240761.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Keith Cirkel',
    handle: 'keithamus',
    title: 'Software Engineer',
    questionOne:
      'I believe UI engineering is an incredibly deep and complex discipline that deserves equal prominence alongside other platform and infrastructure teams; a design system is key to scaling a UI to meet the demands of our users. I think the work of a Design System intersects the concerns of users and colleagues more than most other teams.',
    questionTwo:
      "I'm inspired by art movements like Bauhaus, and the work of Mies van der Rohe, Dame Zaha Hadid, Frank Lloyd Wright. I enjoy the graphic design of Saul Bass. Don Norman's approach to user interfaces inspires me. The software principles of Doug McIlroy, Edsger Dijkstra, Barbara Liskov, Ken Thomson. I'm inspired by the tireless work Sir David Attenborough and Steve & Robert Irwin do to show us the power and beauty of nature. Of course I would not be working in this industry without the work of Tim Berners-Lee, Håkon Wium Lie, Chris Lilley.",
    favoriteTools: 'GitHub, neovim, Firefox',
    cssFeature: 'float: left',
    avatar: 'https://avatars.githubusercontent.com/u/118266?v=4',
    gif: 'https://user-images.githubusercontent.com/118266/198547240-c6bbf2cd-3c3f-4d58-8e1e-0336f90fd48c.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Leslie Cohn-Wein',
    title: 'Engineering Manager',
    handle: 'lesliecdubs',
    questionOne: `As an agency developer early in my career, it seemed we were always building the same UI with different styles. So why not build once, with enough flexibility to customize for many clients?

  Later, it became clear to me that systems could boost the end user's experience through more than speed to production; systems multiply consistency, usability, and accessibility. And who doesn't want a top-notch, inclusive user experience which the makers can ship more quickly?`,
    questionTwo: `We stand on the shoulders of giants. I deeply appreciate the former colleagues who have helped me learn and grow, all of the folks who write and speak in the design systems space, and the work of management leaders like [Lara Hogan](https://larahogan.me/), [Camille Fournier](https://twitter.com/skamille), [Sarah Drasner](https://www.engmanagement.dev/), and Caree Youngman.

  The playful, innovative work of my friend [Charlie Gerard](https://charliegerard.dev/projects) never fails to inspire and remind me what humans in tech are capable of creating.`,
    favoriteTools: `VS Code, React, CSS, axe Accessibility Linter, Netlify, and a big mug`,
    avatar: 'https://user-images.githubusercontent.com/3902488/153995479-15e25bf5-3f81-4f05-8569-213f668590d4.jpeg',
    gif: 'https://user-images.githubusercontent.com/3902488/153995859-eb0b6072-523e-46f5-9850-375a52876716.gif',
    alumni: false,
  },
  {
    name: 'Hector Garcia',
    title: 'Accessibility Software Engineer',
    handle: 'hectahertz',
    questionOne: `Having worked on a few different projects over the years I realized that having a solid design system is one of the best ways to build faster, more consistent, accessible, and overall higher quality products. I love focusing on the details of all the different pieces of a design system and how they fit together to create awesome experiences for both end users and developers.`,
    questionTwo: `I've been fortunate to learn so much from my colleagues over the years! I'm also a big fan of all the people that write and share content with the community. Some of my favorites are [Sara Soueidan](https://www.sarasoueidan.com/), [Sarah Drasner](https://sarah.dev/), [Marcy Sutton](https://marcysutton.com/), [Steve Schoger](https://www.steveschoger.com/), [Suz Hinton](https://noopkat.com/) and [Salma Alam-Naylor](https://whitep4nth3r.com/).`,
    favoriteTools: `iTerm, Visual Studio Code, Edge, Firefox, Microsoft Accessibility Insights, axe Accessibility Linter, GitHub, Spotify, Quip`,
    avatar: 'https://user-images.githubusercontent.com/24622853/154278844-db71cd48-f85c-4c57-bcc0-0a7966e06d7a.jpeg',
    gif: 'https://user-images.githubusercontent.com/24622853/154278835-65fd86da-cf76-41ed-9d81-c9996911c128.gif',
    alumni: false,
  },
  {
    name: 'Charlotte Dann',
    title: 'Accessibility Software Engineer',
    handle: 'pouretrebelle',
    questionOne: `I get so much joy from writing HTML and CSS, and design systems are one level up - systematically making UIs accessible and consistent. I love concentrating on the tiny things like button states so product folk can build the big picture, knowing that the details are taken care of.`,
    questionTwo: `Chris Coyier's [CSS-Tricks](https://css-tricks.com/) was a hugely important resource for me when I was first learning how to make websites, and the community on [CodePen](https://codepen.io/) is an endless source of inspiration. `,
    favoriteTools: `VS Code, DevTools, Figma, Notion, Field Notes, earl grey`,
    avatar: 'https://user-images.githubusercontent.com/1901935/143463395-7eac409f-7ff4-44e0-b074-16754f9a29ec.png',
    gif: 'https://user-images.githubusercontent.com/1901935/142913543-0eb8b113-2377-499f-8f83-1695c6f3a033.gif',
    alumni: false,
  },
  {
    name: 'Chelsea Adelman',
    title: 'Accessibility Systems Designer',
    handle: 'ichelsea',
    questionOne: `The majority of my time as a designer has been within product design, and pursuing my passion for accessible design started early on. I began finding that accessibility and systems thinking were intrinsically linked, so transitioning to design systems to focus on accessibility was a natural course. Being able to affect change that helps people has always been a deep-seated need of mine to fill with my work, and I'm able to do that two-fold in design systems to help product designers and end users.`,
    questionTwo: `Years ago when I was interested in learning how to fix computers, a coworker once told me that you could teach a monkey to build a computer. The meaning behind this was that I could go out and learn whatever I wanted regardless of previous knowledge or background, as long as the desire was there. I really took this to heart with me and it's been a key part of my growth to where I am today. I've been able to learn from countless coworkers over the years who have shared their knowledge and helped me grow.`,
    favoriteTools: `Figma, Microsoft Edge, Microsoft Accessibility Insights, Procreate and GoodNotes on iPad, Obsidian, custom mechanical keyboards`,
    avatar: 'https://user-images.githubusercontent.com/40274682/140833776-28cf21b2-1b59-4a25-857f-713d8a42c1fa.jpg',
    gif: 'https://user-images.githubusercontent.com/40274682/140420359-9c85b4a2-26ae-4818-8415-c33b7c189e00.gif',
    alumni: false,
  },
  {
    name: 'Daniel Guillan',
    title: 'Systems Designer',
    handle: 'danielguillan',
    questionOne: `I first learned about design systems through the work of Joseph Müller-Brockmann, Massimo Vignelli, and Otl Aicher. I was fascinated by their principles, the patterns they developed, the modularity of their approach, and the abstractions they established. All that gave me an entirely new perspective and understanding of design. I later connected these learnings to my work while scaling user interfaces on startups and larger organizations.`,
    questionTwo: `I've been very fortunate to learn and work with many inspiring designers, engineers, and product people over the last few years. Aitor, Anna, Javi, Miguel, and Ricardo, to name just a few.  Also, I've learned a lot about design, process, thinking, and strategy from Oliver Reichenstein and Ryan Singer, and code from Nicolas Gallagher, Harry Roberts, and  Lea Verou.`,
    favoriteTools: `Obsidian, iA Writer, Lamy 2000, Framer, VS Code, Next.js, Vercel, Raycast, coffee, rum`,
    avatar: 'https://user-images.githubusercontent.com/175638/138738100-5d2775c3-c757-47b8-a1b0-cbd5c82a743a.jpg',
    gif: 'https://user-images.githubusercontent.com/175638/138738336-2a11400c-266d-452c-b48b-766baa25641e.gif',
    alumni: false,
  },
  {
    name: 'Reza Rahman',
    title: 'Software Engineer',
    handle: 'rezrah',
    questionOne: `Design Systems gave me a unique opportunity to combine two of my passions: systems engineering with design operations. I find pleasure in the small details, like drawing component schematics, automating repetitive workflows, writing specifications, etc. Crucially, I enjoy building systems that others can use to optimise their own workflows and lives.`,
    questionTwo: `I'm inspired by engineering thought leaders like Kent C. Dodds, Brian Lonsdorf (AKA Prof. Frisby), Eric Elliot, Dan Abramov, and all of my current and former colleagues in the space. I also enjoy following OG design system pioneers like Jina, Brad Frost, Nathan Curtis, Dan Mall and the work of teams like [@salesforceux](https://twitter.com/SalesforceUX), [@materialdesign](https://twitter.com/materialdesign), [@spotifydesign](https://twitter.com/spotifydesign), and it should go without saying… the team at [@primer](https://github.com/primer).`,
    favoriteTools: `Spotify, Figma, Photoshop, React + React Native, AWS, Notes (I know...), Flat Whites`,
    avatar: 'https://user-images.githubusercontent.com/13340707/138252080-2a094636-fa13-4d70-b60d-93fdefa5e890.jpg',
    gif: 'https://user-images.githubusercontent.com/13340707/138258250-a2d9a33b-9e1b-4053-849f-0fa451065fa0.gif',
    alumni: false,
  },
  {
    name: 'Siddharth Kshetrapal',
    title: 'Software Engineer',
    handle: 'siddharthkp',
    questionOne: `Through pure frustration of “there has to be a better way”, I wandered into the world of design systems and it just clicked! Found so many talented folks who worked on the intersection of design and development roles (used to be called "hybrids", but that might be out of fashion). Bought into the idea of defining a common language for designers and developers, still chasing that dream :)`,
    questionTwo: `People I have worked with over the years ♥️ and the people I’ve looked up to — [Nicole Sullivan](https://github.com/stubbornella), [Jina Anne](https://github.com/jina), [Jacob Thornton](https://github.com/fat), [Brent Jackson](https://github.com/jxnblk), [Sunil Pai](https://github.com/threepointone), [Sarah Drasner](https://github.com/sdras), [Addy Osmani](https://github.com/addyosmani) and my sister!`,
    favoriteTools: `Devtools, Figma, iA Writer, GitHub, CodeSandbox, Hyper, Vercel, Kap, Muji pens!`,
    avatar: 'https://avatars.githubusercontent.com/u/1863771?v=4',
    gif: 'https://user-images.githubusercontent.com/1863771/145415749-f8a9eea3-2b01-4e8a-8462-161bf1a602b1.gif',
    alumni: false,
  },
  {
    name: 'Katie Langerman',
    title: 'Systems Designer',
    handle: 'langermank',
    questionOne: `I have a background in design but also love writing code, and design systems are very welcoming to multi-disciplinary folks. I love how design systems bring people together across orgs and create a shared language for collaboration.`,
    questionTwo: `Medium posts from [Nathan Curtis](https://medium.com/@nathanacurtis) kept me afloat while I worked on my first design system, along with the community in Jina's [Design Systems slack](https://design.systems/).

  I've learned my favorite CSS trickery from [Andy Bell](https://piccalil.li/), [Rachel Andrew](https://rachelandrew.co.uk/css/), [Stephanie Eckles](https://thinkdobecreate.com/), [Sara Soueidan](https://www.sarasoueidan.com/), and [Una](https://una.im/).

  Various design podcasts including [SimpleBits](https://show.simplebits.com/), [Honest Designers](https://www.honestdesigners.com/), [Design Details](https://designdetails.fm/), [Design Better](https://www.designbetter.co/podcast), and [CSS Podcast](https://thecsspodcast.libsyn.com/)`,
    favoriteTools: `VS Code, Firefox dev tools, CSS, Figma, Pika, Notion`,
    avatar: 'https://user-images.githubusercontent.com/18661030/133150912-f74c0a6b-2e8a-4547-9d80-872a7d0ff8db.jpg',
    gif: 'https://user-images.githubusercontent.com/18661030/133155524-f0633783-d6ef-4b3a-938a-d30cdb6364da.gif',
    alumni: false,
  },
  {
    name: 'Maxime De Greve',
    title: 'Systems Designer',
    handle: 'maximedegreve',
    questionOne: `Being part of a few startups I've always noticed that engineers quickly outgrew designers and when this happened it was hard to keep up as a designer. Small obvious design patterns would often need clarification because they weren't clear. System thinking has always reduced this problem and allowed us to move quickly without compromising on quality.`,
    questionTwo: `Co-workers have always played a crucial part in growing into system thinking. Working as a designer on a design tool in the past I was also lucky to chat to endless of big companies trying to set up design systems as well.`,
    favoriteTools: `React, XCode, Figma, GitHub, Atom and 4pm biscuits`,
    avatar: 'https://user-images.githubusercontent.com/980622/132508908-950856ba-550f-4c7b-ba28-eb9b3a5250bc.jpg',
    gif: 'https://user-images.githubusercontent.com/980622/132508959-aa279711-0193-47d3-b51b-68a64e9d402b.gif',
    alumni: false,
  },
  {
    name: 'Allie Thu',
    title: 'Accessibility Design Manager',
    handle: 'alliethu',
    questionOne:
      "Early on in my career, I felt a natural pull towards design systems as I've always been a person who finds great joy in consolidating, organizing, and making sense of messy things. As soon as I began to specialize in accessibility, the power and importance of design systems became even more clear, ultimately turning into the core of my work.",
    questionTwo: `I believe I inherited my curiousity and love for learning from my dad. My husband, who has helped teach me that I can be both a #bossatwork and a #bossmom. The Accessibility community who is small, but mighty, working hard to empower and educate people like me to grow the community even further. Lastly, all the open source design systems out there!`,
    favoriteTools: 'Notion, Slack, Figma, Microsoft Accessibility Insights, Spotify',
    avatar: 'https://user-images.githubusercontent.com/7228610/130680358-cd3a8b01-4fae-4e2c-ba05-e9ad6dd5cb14.jpg',
    gif: 'https://user-images.githubusercontent.com/7228610/183948245-6004efcf-1d90-40c0-8aba-22c4cdb6f7c1.gif',
    alumni: false,
  },
  {
    name: 'Mike Perrotti',
    title: 'Systems Designer',
    handle: 'mperrotti',
    questionOne:
      'I was frustrated by all of the inconsistent UI, duplicated work, and redundant meetings that my teams would have to deal with. I finally found a discipline where I could use my design skills and my engineering skills to solve meaty problems without being limited to a traditional design or engineering role.',
    questionTwo: `Working on a design system with Adam Detrick was invaluable and taught me so much. I'm also humbled and inspired by the work of David DeSandro, Lynn Hershman, Rachel Andrew, and Wei Huang.`,
    favoriteTools: 'VS Code, Zsh, CSS, React, Adobe Illustrator, pen and paper, Microsoft To Do, Chrome Inspector',
    avatar: 'https://user-images.githubusercontent.com/2313998/129769461-a446975e-cbc8-45eb-870a-c19d6a5e13e4.png',
    gif: 'https://user-images.githubusercontent.com/2313998/129771358-b6808a2d-d48a-4e62-b227-9da168e047ac.gif',
    alumni: false,
  },
  {
    name: 'Cameron Dutro',
    handle: 'camertron',
    title: 'Software Engineer',
    questionOne:
      "I'm a big fan of useful abstraction layers, and design systems are generally great examples. Although I've been a programmer for a long time, I enjoy dabbling in design in my free time and love to see systems that make good design achievable (nay, easy!) for non-designers :)",
    questionTwo:
      'Whenever I need some design inspiration, I head over to [Dribbble](https://dribbble.com/). When I was a kid, I learned a ton from my mom who was a graphic designer for REI and Eddie Bauer in the 1980s (i.e. before computers).',
    favoriteTools: 'VScode, Ruby, Chrome, iTerm 2',
    avatar: 'https://avatars.githubusercontent.com/u/575280?v=4',
    gif: 'https://user-images.githubusercontent.com/575280/129806447-88b7a872-3d27-4ab3-b7d0-af731293b6d7.gif',
    alumni: false,
  },
  {
    name: 'Natalya Shelburne',
    title: 'Design Infrastructure Manager',
    handle: 'tallys',
    cssFeature: `background-color: hotpink`,
    questionOne: `Technology and design being considered at odds is a recent invention. Design systems are just the way I think, so building bridges between design and engineering is at the heart of much of my work.`,
    questionTwo: `I am fortunate to say that the list of people who have taught and inspired me is long. I am full of gratitude for the people who take the time and energy to teach, build tools for collaboration, and run communities that lift people up.`,
    favoriteTools: `Sublime Text, React, Pencil and Paper, Figma, GitHub, coffee`,
    avatar: 'https://user-images.githubusercontent.com/6720549/119029291-7418e480-b976-11eb-9641-7c47e8134414.jpg',
    gif: 'https://user-images.githubusercontent.com/6720549/118991044-63a24300-b951-11eb-81d4-d5a63f71db06.gif',
    alumni: false,
  },
  {
    name: 'Julius Yanik',
    title: 'Systems Designer',
    handle: 'juliusschaeper',
    cssFeature: `grid-template-areas: "header header header" "footer footer footer"`,
    questionOne: `Being able to focus on the key elements of design, form, color and typography.`,
    questionTwo: `Having an education in industrial design, the Bauhaus design philosophy and Dieter Rams inspired me a lot. Everything I know about digital product design I learned at Wunderlist, whose design team was very inspiring.`,
    favoriteTools: `Remarkable tablet, Figma, Origami, Glyphs, GitHubs internal color tool`,
    avatar: 'https://user-images.githubusercontent.com/6720549/120377556-470bf080-c2eb-11eb-8230-53db30314b15.jpg',
    gif: 'https://user-images.githubusercontent.com/6720549/120376656-22fbdf80-c2ea-11eb-81ec-384cbd2f9f50.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Michelle Tilley',
    handle: 'BinaryMuse',
    title: 'Software Engineer',
    questionOne:
      "Over the decades, my primary expertise has slowly migrated from back-end development to front-end. I've always had an interest in design, and working on design systems allows me to explore that interest while continuing to leverage my expertise in front-end engineering.",
    questionTwo:
      "The constant shifts in front-end programming over the years and an intrinsic desire to learn have always been a driving factor in my own exploration of computer programming. I'm fortuante to work with a huge number of talented engineers and designers at GitHub, which is very inspiring and very rewarding.",
    favoriteTools: 'Vim, Photoshop, Blender, and a good, old-fashioned shell prompt.',
    avatar: 'https://user-images.githubusercontent.com/189606/75723431-29d41680-5c91-11ea-99ba-40e24813c1c0.jpg',
    gif: 'https://user-images.githubusercontent.com/189606/75723924-1ffee300-5c92-11ea-873c-8335de7eece6.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Javier Díaz',
    handle: 'edokoa',
    title: 'Systems Designer',
    questionOne:
      'I think it happened naturally while working on bigger projects and having to define a source of truth. Producing and mantaining complex products is really difficult if you have to reinvent the wheel going through all the decision-making process again and again, alone or with your teammates.',
    questionTwo:
      'My biggest source of inspiration have always been video games, I really like how good games craft a small reality and then put a lot of care into making interaction feel satisfactory and self-explanatory. Apart from that, I think there are lots of places inspiration can be drawn from: Music, furniture and interior design, photography, movies... ',
    favoriteTools: 'Photoshop, Figma, Maya, Unity, VS Code, PocketCasts, CityMapper.',
    avatar: 'https://user-images.githubusercontent.com/10384315/75708333-7c9fd500-5c75-11ea-9b37-e64dbc32d1d2.jpg',
    gif: 'https://user-images.githubusercontent.com/694831/75690516-48dba400-5ca3-11ea-9e9a-e033c612c827.gif',
    alumni: false,
  },
  {
    name: 'Inayaili León',
    handle: 'yaili',
    title: 'Design Producer',
    questionOne:
      'I like it when things around me are organized. I like writing, and the process of refining an idea into words. I like guidelines, style guides, patterns, HTML, and CSS. I like to have several projects on at once. I like planning. I like to work smart, not hard. When you add all of these you get… design systems!',
    questionTwo:
      'More than anything, the people I’ve worked with over the years. I learned something from everyone: being generous with my knowledge, being serious about research, work discipline, balancing family life and work, speaking up, picking my battles, and everything good that I know. Also: the conversations and connections I made during the early years of Design Twitter — still going strong.',
    favoriteTools:
      'Too many! Things, Simplenote, iA Writer, Dictionary, VS Code, xScope, notebooks, Pinboard, Overcast, spreadsheets, and Earl Grey.',
    avatar: 'https://user-images.githubusercontent.com/10384315/75708294-67c34180-5c75-11ea-9f9b-db9a11530bdd.jpg',
    gif: 'https://user-images.githubusercontent.com/223966/75572471-be046a80-5a52-11ea-924e-9f6d7aca6d3d.gif',
    alumni: false,
  },
  {
    name: 'Joel Hawksley',
    handle: 'joelhawksley',
    title: 'Software Engineer',
    questionOne:
      'While working in numerous Rails codebases over the years, I kept seeing the same structural issues in the view layer as applications grew in size. I came up with an idea to bring component-driven design patterns to Rails, and am thrilled to be pursuing it for the purpose of building out our design systems at GitHub.',
    questionTwo:
      'My mom is a professor in visual communication, so I’ve been in the design world since before I could walk. I learned how to be a software engineer from my friend Aaron Snyder, who took me as his apprentice after I left the newspaper industry. These days I learn a lot from my coworkers, especially [tenderlove](http://tenderlovemaking.com//) and [seejohnrun](http://seejohncode.com/).',
    favoriteTools: 'VS Code, Todoist, Spotify',
    avatar: 'https://user-images.githubusercontent.com/10384315/75709494-9f32ed80-5c77-11ea-86eb-e798fcfcaa5d.jpg',
    gif: 'https://user-images.githubusercontent.com/1940294/75078886-191ee600-54c4-11ea-883c-93e1815e75a6.gif',
    alumni: false,
  },
  {
    name: 'Aurora Pleguezuelo',
    handle: 'auareyou',
    title: 'Systems Designer',
    questionOne:
      'A few years back, I was an apprentice for a freelance product designer and my main responsibility was to document his designs and help his customers understand how to continue on with our designs. I loved extracting patterns and spending time writing documentation so every chance I had at any job after that I either participated or initiated the design systems efforts.',
    questionTwo: `I'll start with a lesson from a book. [Mark Manson](https://markmanson.net/) helped me stop looking for shortcuts. [Javier Cañada](http://www.terremoto.net/) pushed me to think about design outside technology and be inspired by history, literature, philosophy... [Sasha Prokhorova](https://blog.algolia.com/faces-algolia-meet-sasha-prokhorova/) taught me that design is not just about craft. She made me a better professional along with some others who I consider my mentors like [David la Iglesia](https://dribbble.com/de_la_Iglesia), [Adrian Mato](https://adrianmato.com/) or [Emily Hayman](http://emilyhayman.com/). From [Yaili](https://yaili.com/) I learned to love design systems documentation and how to show others the value of it. Then [Nathan Curtis](https://medium.com/@nathanacurtis) content became the manual on how to write and present documentation. [Yesenia](https://www.yeseniaperezcruz.com/) helped me see how design systems can be functional, effective and still convey a brand's personality. The most important lesson, be it design or human level is that communities help us grow and learn from one another. This is why I'm so inspired by people like [Jina](https://www.sushiandrobots.com/), [Mina](http://mina.codes/), [Diana](https://broccolini.net/) or [Stu](https://www.alwaystwisted.com/) who are passionate thought leaders doing so much for the design and development industry. Also [Zendaya](https://www.instagram.com/zendaya/). What a great time to be alive!`,
    favoriteTools: 'Figma, Things, SPREADSHEETS!, Notion, Emojis, Moleskine journals and Fujifilm X-H1.',
    avatar: 'https://user-images.githubusercontent.com/10384315/75709531-afe36380-5c77-11ea-9b94-27f780901284.jpg',
    gif: 'https://user-images.githubusercontent.com/6951037/66052384-cf435c80-e530-11e9-958d-c33476b7ef96.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Vinicius Depizzol',
    handle: 'vdepizzol',
    title: 'Systems Designer',
    questionOne:
      'Throughout the various roles I’ve had in product design, creating good and cohesive experiences for people was always what truly motivated me to be in this area. As I started working in larger projects, style guides, design patterns and design systems became essential to scale cohesion, and ultimately part of my life.',
    questionTwo: `I’ve been lucky to encounter and collaborate with immensely talented people where I’ve been — that has shaped me more than I can imagine. Over the years, stumbling upon [Bruno Bergher](https://brunobergher.com/), [Lulu LaMer](https://twitter.com/Lulu), [Andrew Branch](https://blog.andrewbran.ch/), among many many others, have shown me people who are eager to learn, and above all open to being wrong (even if they’re right most of the time). \n \n I like how [Yuval Noah Harari](https://en.wikipedia.org/wiki/Yuval_Noah_Harari) has helped me (and an entire generation) to see the big picture, and I have always been inspired by designers who spread beyond their areas like [Frank Chimero](https://frankchimero.com/) and [Rasmus Andersson](https://rsms.me) do.`,
    favoriteTools: 'Pen and paper, Figma, photography camera',
    avatar: 'https://user-images.githubusercontent.com/293280/64046286-6d9e6580-cb20-11e9-97da-47fb76a4ee36.JPG',
    gif: 'https://user-images.githubusercontent.com/293280/64045892-501ccc00-cb1f-11e9-99fb-ee1b8f7ce6ce.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Cole Bemis',
    title: 'Design Systems Engineer',
    handle: 'colebemis',
    cssFeature: 'grid-gap',
    questionOne: `While studying computer science, I developed a passion for design patterns and abstractions. I especially enjoyed learning about programming languages and automata theory. I'm excited about the potential to apply what I've learned to create strong *visual* languages. I also enjoy building tools that make it easier for designers and developers (like me) to create better user experiences.`,
    questionTwo: `Too many to list! I would not be in this field if it weren't for my mom, who introduced me to HTML, CSS and JavaScript at a young age. Tom Falkner, Ben Waldron, Tyler Deitz, Tony Gines, Brett Spradling, Jake Swenson and Sam Ng have all been incredible mentors. And I'm constantly inspired by the work of [Brent Jackson](https://twitter.com/jxnblk), [Adam Morse](https://twitter.com/mrmrs_), [James](https://twitter.com/jamesm), [Max Stoiber](https://twitter.com/mxstbr), [Brian Lovin](https://twitter.com/brian_lovin), [Bryn Jackson](https://twitter.com/superbryntendo), [Steve Schoger](https://twitter.com/steveschoger), [Sara Soueidan](https://twitter.com/SaraSoueidan), [Dan Abramov](https://twitter.com/dan_abramov) and so many more.`,
    favoriteTools: 'CodeSandbox, Figma, GitHub, React, Slack, VS Code',
    avatar: 'https://user-images.githubusercontent.com/4608155/59656806-959e3f80-9153-11e9-99cc-25b33e575ad8.jpg',
    gif: 'https://user-images.githubusercontent.com/4608155/59657078-73f18800-9154-11e9-8b36-81eed35e1266.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Zain Khoja',
    title: 'Systems Designer Intern',
    handle: 'zainkho',
    cssFeature: 'fill: currentColor;',
    questionOne: `I love organization and lists. I have a lists for things from movies to boba tea orders. I like to bring this into my design work as well, creating components and cohesiveness across platforms to make it easier for people to create great products.`,
    questionTwo: `When I first began learning about design, I was inspired by the people who contributed back to the community by teaching and opening up to students. Resources like [Students Who Design](https://studentswho.design/) and [Design Details](https://designdetails.fm) helped me figure out how I wanted to make a positive impact in tech.

    More recently, I've been inspired by design agencies and companies that are testing the boundaries of design outside of what is accepted as 'common pratice'. I enjoy seeing unique animation and motion used to convey information, emotion, and storytelling.`,
    favoriteTools: 'Sketch, Figma, Atom, Dropbox Paper, Twitter, Ping Pong, Ukulele',
    avatar: 'https://user-images.githubusercontent.com/6846673/59784198-bb703500-9276-11e9-8ec6-a02fc3eb2fc5.jpg',
    gif: 'https://user-images.githubusercontent.com/6846673/59376179-7a22d700-8d04-11e9-91da-09e45112e162.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Simon Lüthi',
    title: 'Systems Designer',
    handle: 'simurai',
    cssFeature: 'fill: currentColor;',
    questionOne: `Ok, this might sound weird, but I really enjoy looking at UIs. There is something satisfying, seeing a bunch of components getting thrown on a page that fit well together, forming a balanced interface for users to interact with. I also enjoy building UIs and helping others to do the same.`,
    questionTwo: `Thinking back, seeing the CSS Zen Garden was really inspiring. Also the CSS "demo scene" found in places like [CodePen](https://codepen.io/) is nuts. Admittedly, not always practical, but fun to check out.

    I also got inspired and learned a lot from methodologies like OOCSS, SMACSS, BEM, SUIT and nowadays CSS-in-JS. The "how to organize CSS" is a challenging, but also interesting problem to solve.`,
    favoriteTools: 'DevTools, Atom, Figma, Sketch, iA Writer, LICEcap, Things, coffee, headphones.',
    avatar: 'https://user-images.githubusercontent.com/378023/54094782-ad448a00-43e6-11e9-93e0-b5960fa86e89.png',
    gif: 'https://user-images.githubusercontent.com/378023/53881412-fbf4cb80-4056-11e9-835b-3b4585e7e203.gif',
    alumni: false,
  },
  {
    name: 'Joshua Shao',
    title: 'Apprentice UI Designer',
    handle: 'jhuashao',
    cssFeature: 'align-items: center;',
    questionOne: `I first found out about design systems through my last internship, which was focused on building up a design system for the New York Times. I kept learning more and more about this emerging field, and really started to grow an affinity for it.

    Design Systems seemed to blend all aspects of my interests: Product Design, Front-End Development, Graphic Design, Typography, Illustration, etc. I’m drawn to design systems as it’s a field that welcomes individuals who have too many interests to count, and find themselves at intersections of many different passions. `,
    questionTwo: `I draw in a lot of inspiration from so many different facets of my life. I’m really fortunate to come from a diverse set of backgrounds to be able to see parallels that emerge from a variety of industries.

    The field of Graphic Design has a tremendous influence on me, and some of the designers and studios I’m inspired by are: [Natasha Jen](https://www.pentagram.com/about/natasha-jen), [Barron Webster](https://barronwebster.com/), [Maxime Buschi](https://www.youtube.com/watch?v=diBifN7qBgw), [Michael Bierut](https://www.pentagram.com/about/michael-bierut), [Eric Hu](http://erichu.info/), [Micah Barrett](http://micahbarrett.design/), [Draw Down Books](https://draw-down.com/), [Wei Huang](https://twitter.com/w__h_?lang=en), [Josh Schaub](http://joshschaub.ch/), [Morcoskey](https://morcoskey.com/), and [David Rudnik](https://davidrudnick.org/). I’ve always been inspired by those who are experimenting at the intersection of design and technology: [Tristan Harris](http://www.tristanharris.com/), [Brad Frost](http://bradfrost.com/), [Studio No-Plans](https://no-plans.com/), [Marie Otsuka](http://motsuka.com/), [Ben Wilkins](https://twitter.com/thatbenlifetho?lang=en), [Jon Gold](https://jon.gold/), [Bret Victor](http://worrydream.com/),  and [Chris Novello](http://chrisnovello.com/). I also love typography, and have a lot of admiration for type-designers and foundries: [Colophon Foundry](https://www.colophon-foundry.org/), [Kris Sowersby (Klim Type)](https://klim.co.nz/), [Matthew Carter](https://www.myfonts.com/person/Matthew_Carter/), [Richard Lipton](https://lipton.typenetwork.com/), [Lucas Sharp](http://lucassharp.com/), [Grilli Type](https://www.grillitype.com/), and [James Edmondson (OHnotypeco)](https://ohnotype.co/). There’s so many more people that I’ve learned and drawn inspiration from to mention and praise, so feel free to reach out! I’d love to share and discuss. <3`,
    favoriteTools: 'VScode, Figma, Adobe Creative Suite, Zero-Sugar Redbull',
    avatar: 'https://user-images.githubusercontent.com/24916540/47465791-490c8b00-d7a3-11e8-8dc8-9cc968a47b62.jpg',
    gif: 'https://user-images.githubusercontent.com/24916540/50362777-e2df8280-051d-11e9-8225-a0ed066eac59.gif',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Ash Guillaume',
    title: 'Systems Designer',
    handle: 'ashygee',
    cssFeature: 'align-items: center;',
    questionOne: `I came into design systems very organically. When I began my career in the agency world, I worked within constraints of branding and style guidelines of our various clients. At the time I didn't fully recognize these guidelines as what we now refer to as design systems. As my career continued to grow I noticed that I began to be far more interested in how various companies/brands packaged the many pieces that made up their brand and/or products. I love how design systems bring together all the pieces in order to unleash endless creativity but still maintain the necessary constraints.`,
    questionTwo: `I get a lot of my inspiration from the community I've built via Dribbble. A few of my favorites are the dynamic duo that makes of [TheLittleLabs](https://www.thelittlelabs.com/), Camilo and Aradhana, the illustration work by [Enisaurus](https://www.enisaurus.com/), and [Kendrick Kidd](http://www.kendrickkidd.com/).

    When I first started my career, [Frank Chimero](https://frankchimero.com/) was a huge inspiration. His book, [The Shape of Design](https://frankchimero.com/design/the-shape-of-design/), really helped to form my philosophy and approach to problems and how to cultivate a design process. Along with that but not design related, [Jason Fried](https://twitter.com/jasonfried) has been a great inspiration to how I approach work. His book (written alongside David Heinemeier Hansson) [Rework](https://basecamp.com/books/rework) still is the best, no nonsense, business book I've ever read.`,
    favoriteTools: 'Figma, Atom, Keynote, Bear Notes, Spark Mail, Spotify',
    avatar: 'https://user-images.githubusercontent.com/10384315/48450021-61941380-e759-11e8-90e0-7b41f6cbb6e8.jpg',
    gif: 'https://camo.githubusercontent.com/c325b2b42ec71db51a92d7cdb2a6684824bc0464/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f7854687557686f614e794e426a54474552612f67697068792e676966',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Emily Brick',
    title: 'Systems Designer',
    handle: 'emilybrick',
    cssFeature: 'align-items: center;',
    questionOne: `I was initially drawn to design systems while working on a small team, where we were stretched thin and struggling to keep the front-end and user experience afloat, recreating components and patterns by hand over and over again. We needed consistency, stability, a bridge between designers and developers, and a more efficient workflow. Once we put a design language and system in place, I was sold on the value. I find it fascinating how many approaches to design systems there are out there, and how different they can be depending on the individual product and teams’ needs.`,
    questionTwo: `[Inge Druckrey](https://www.youtube.com/watch?v=ldSkPqZKBl0) and [Edward Tufte](https://www.edwardtufte.com/tufte/books_ei) inspired me to get into design, and introduced me to the world of typographic and informational systems (simultaneously). This talk by [Bret Victor](https://vimeo.com/36579366) helped me feel less alone when I was starting out in the industry; at a time when code and design weren't yet openly intertwined. I’m also inspired by the folks behind [designbetter.co](https://www.designbetter.co/design-systems-handbook) for making knowledge around design systems transparent and available to everyone.  Additionally, and there are too many people to name, people like [Una Kravetz](https://twitter.com/Una?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor), [Sarah Drasner](https://twitter.com/sarah_edo), [Tim Holman](https://twitter.com/twholman), and [Val Head](https://twitter.com/vlh) are always on my radar, keeping the internet weird and creative and continuously pushing the boundaries of what's possible.`,
    favoriteTools: 'Atom, Figma, Bear, Dash, Slack',
    avatar: 'https://user-images.githubusercontent.com/586552/46690197-b4fdc980-cbcf-11e8-9ad2-57894e3dee92.jpg',
    gif: 'https://user-images.githubusercontent.com/586552/50305051-ba914e80-045f-11e9-9a13-b0e918da2086.gif',
    alumni: false,
  },
  {
    name: 'Emily Plummer',
    title: 'Design Systems Engineer',
    handle: 'emplums',
    cssFeature: 'word-break: break-word',
    questionOne: `Up until now, I've been mostly working Front End Engineering roles, but I've always been interested in finding something that was a good mix between Front End and Design. Design Systems feels like the perfect hybrid between the two! \n \n I'm also really into accessibility, and I found that having a design system that enforces accessible standards is one of the easiest ways to make progress on getting a team on-boarded with accessibility best practices and getting those practices implemented in production \n \n I'm super excited about our React component library that we've been working on over the past few months!`,
    questionTwo: `Some people & projects that have been inspiring to me are: are Nicole Sullivan, Vaidehi Joshi's [BaseCS blog](https://medium.com/basecs) &
       , Mina Markham's [slay.sh](https://slay.sh/), Una Kravets, Suz Hinton's
       [talk about accessibility at Dinosaur.js](https://www.youtube.com/watch?v=hKIQkgPVXH4), Marcy Sutton, and Anil Dash.`,
    favoriteTools: 'Figma, Notion (for taking notes & planning), React',
    avatar: 'https://user-images.githubusercontent.com/586552/46690198-b4fdc980-cbcf-11e8-95e3-c3e28b69a29c.jpg',
    gif: 'https://camo.githubusercontent.com/4646a6d24f7c08a84028f83987a8ec8462179c11/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f6441775547537553356b7157732f67697068792e676966',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Shawn Allen',
    title: 'Systems Designer',
    handle: 'shawnbot',
    cssFeature: 'box-sizing: border-box',
    questionOne: `I worked in data visualization and map-making for most of my career, and solving design problems with data is my jam. To me there's something uniquely exciting about making a thing that can take complicated and/or unexpected input and, with no human intervention, reduce it to something visually comprehensible—and hopefully interesting!`,
    questionTwo: `Anyone who has ever contributed to [A List Apart](http://alistapart.com/) or posted to the [webdesign-l](http://www.webdesign-l.com/) mailing list between 1999 and 2005. The [CSS Zen Garden](http://www.csszengarden.com/) was hugely inspirational for me, along with web standards pioneers like Eric Meyer, Mark Pilgrim, and Jeff Zeldman. [Jina](https://twitter.com/jina) is one of the hardest-working people in design systems, and her leadership in the community is a tremendous inspiration. I learned everything I know about SVG from [Sara Soueidan](https://twitter.com/SaraSoueidan), and everything I could _hope_ to know about CSS Grid from [Rachel Andrew](https://twitter.com/rachelandrew) and [Jen Simmons](https://twitter.com/jensimmons). If you want to learn about web components, follow [Monica Dinculescu](https://twitter.com/notwaldorf) and [Mu-An Chiou](https://twitter.com/muanchiou).`,
    favoriteTools: 'Vim, Things, Chrome DevTools, curl',
    avatar: 'https://user-images.githubusercontent.com/586552/46751266-f81b7380-cc87-11e8-8459-2166a1c40f63.jpg',
    gif: 'https://camo.githubusercontent.com/ab7c209f3da3ff14493ff129778589612df7cd8d8ec9b885ca2ed8619bd721a1/68747470733a2f2f692e67697068792e636f6d2f6d656469612f6b484f6c7a4c774a44566f4e572f67697068792e77656270',
    alumni: true,
    color: 'orange',
  },
  {
    name: 'Jon Rohan',
    title: 'Systems Designer',
    handle: 'jonrohan',
    cssFeature: `grid-template-areas: "header header header" "footer footer footer"`,
    questionOne: `I have had a fascination with front end engineering and UI for all of my career. Early on at GitHub I recognized the need to organize our code into reusable components. When the team formalized I had to be involved.`,
    questionTwo: `Both past and present I have to acknowledge that Nicole Sullivan has been profoundly influential in the way I front-end and write CSS. When she introduced concepts of OOCSS it brought me from thinking very linearly about CSS to a whole new range of systems thinking.`,
    favoriteTools: `Atom, Bash/Shell, Figma, GitHub, iTerm, Javascript, MacBook Pro, Things`,
    avatar: 'https://user-images.githubusercontent.com/586552/46690199-b4fdc980-cbcf-11e8-9f8d-59a694281b70.jpg',
    gif: 'https://user-images.githubusercontent.com/54012/50536666-fced1680-0b56-11e9-89e6-995cdb01a599.gif',
    alumni: false,
  },
  {
    name: 'Diana Mounter',
    handle: 'broccolini',
    title: 'Design Infrastructure Director',
    cssFeature: 'text-decoration: underline wavy',
    questionOne:
      'I was drawn to design systems for many reasons—the opportunity to improve design and development workflow efficiency; the potential benefits well-design systems can bring to the user experience; I like to bring order to chaos and enjoy working at the intersection of design and code. I also love that there is still so much to learn in the field and really enjoy the passion of the community behind design systems.',
    questionTwo: `I don't have room on this webpage to list everyone! [SMACSS](https://smacss.com/), [The Art and Science of CSS](https://www.amazon.com/Science-Jonathan-Bolton-Cameron-Paperback/dp/B00ZVORUW6), and [Veerle Pieters](http://veerle.duoh.com/) were some early influences. [OOCSS](https://github.com/stubbornella/oocss/wiki) by Nicole Sullivan, [CSS Wizardary](https://csswizardry.com/), [Basscss](http://basscss.com/), [Tachyons](https://tachyons.io/), and [BEM](http://getbem.com/naming/) influenced my approach to CSS architecture. More recently I've been inspired by Rune Madsen's [Programming Design Systems](https://programmingdesignsystems.com), [Compositor](https://github.com/c8r), [Dan Abramov](https://twitter.com/dan_abramov), [Sebastian Markbage](https://youtu.be/4anAwXYqLG8), and so many design and code wonder women like [Mandy Kerr](https://twitter.com/mandy_kerr), [Marcy Sutton](https://twitter.com/marcysutton), [Lea Verou](https://twitter.com/LeaVerou), [Mina Markham](https://twitter.com/MinaMarkham), [Sarah Drasner](https://twitter.com/sarah_edo) and [Jina Anne](https://twitter.com/jina).`,
    favoriteTools: 'Hyper, Atom, Figma, MDN, and Quip',
    avatar: 'https://user-images.githubusercontent.com/586552/46751001-5c8a0300-cc87-11e8-84bb-9024b03df74f.jpg',
    gif: 'https://user-images.githubusercontent.com/334891/50363219-bab14d00-0538-11e9-9595-48b3c45358ab.gif',
    alumni: false,
  },
]

export default teamContent
