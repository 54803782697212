module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js"},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/design/design"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f3da5bc9ee21801506bcf83c84f79ae4"},
    },{
      plugin: require('../node_modules/@primer/gatsby-theme-doctocat/gatsby-browser.js'),
      options: {"plugins":[],"defaultBranch":"main"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
