import { ComponentPageNav } from '~/src/components/component-page-nav';
import StatusMenu from '~/src/components/status-menu';
import { PropsTable } from '~/src/components/props-table';
import { AccessibilityLabel, StatusLabel } from '@primer/gatsby-theme-doctocat';
import { Label } from '@primer/react';
import { graphql } from "gatsby";
import * as React from 'react';
export default {
  ComponentPageNav,
  StatusMenu,
  PropsTable,
  AccessibilityLabel,
  StatusLabel,
  Label,
  graphql,
  React
};